import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../components/header/header.jsx';
import Sidebar from '../components/sidebar/sidebar.jsx';
import Whatsapp from '../components/footer/whatsapp.jsx';
import ThemeRoutes from '../routes/routing.jsx';
import { hotjar } from 'react-hotjar';
import SplashScreen from '../components/animations/SplashScreen'
import Footer from '../components/footer/footer'

export default class Fulllayout extends React.Component {
    state = {
        width: window.innerWidth,
        renderSplashscreen: true,
        landing: false,
        package: false
    };

    componentDidMount() {

        hotjar.initialize(2058668, 6)

        this.props.history.listen((location, action) => {
            if (
                window.innerWidth < 1300 &&
                document
                    .getElementById('main-wrapper')
                    .className.indexOf('show-sidebar') !== -1
            ) {
                document
                    .getElementById('main-wrapper')
                    .classList.toggle('show-sidebar');
            } else {

            }
        });

        if (window.location.href.indexOf("package") > 0) {
                const elem = document.getElementById('sidebarbg')
                elem.parentNode.removeChild(elem);
                this.setState({ package: true });
        } else {
            if (window.innerWidth > 1024) {
                const elem = document.getElementById('sidebarbg')
                elem.parentNode.removeChild(elem);
                window.location.replace("/#/landing1");
                this.setState({ landing: true });
            }
        }

        function sleep(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        }

        const updateDimensions = async () => {
            let element = document.getElementById('main-wrapper');
            this.setState({ subscription: window.innerWidth })
            await sleep(1000);
            this.setState({ renderSplashscreen: false });

            if (this.state.width < 1170) {
                element.setAttribute("data-sidebartype", "mini-sidebar");
            } else {
                element.setAttribute("data-sidebartype", "full");
                element.classList.remove("mini-sidebar");
            }
        }
        if (document.readyState === "complete") {
            updateDimensions();
        }
        window.addEventListener("load", updateDimensions.bind(this));
        return () => {
            window.removeEventListener("load", updateDimensions.bind(this));
        };
    }

    render() {
        return (
            <>
                <SplashScreen style={(!this.state.renderSplashscreen) ? { display: "none" } : {}} />
                <div
                    id="main-wrapper"
                    data-theme="light"
                    data-layout="vertical"
                    data-sidebartype="full"
                    data-sidebar-position="fixed"
                    data-header-position="relative"
                    data-boxed-layout="full"
                    style={(this.state.renderSplashscreen) ? { display: "none" } : {}}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Header                                                                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    { !(this.state.package) && <Header {...this.props} /> }
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Sidebar                                                                        */}
                    {/*--------------------------------------------------------------------------------*/}
                    <Sidebar {...this.props} routes={ThemeRoutes} />
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Page Main-Content                                                              */}
                    {/*--------------------------------------------------------------------------------*/}
                    <div className="page-wrapper d-block">
                        <div className="page-content container-fluid">
                            <Switch>
                                {ThemeRoutes.map((prop, key) => {
                                    if (prop.redirect) {
                                        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                    }
                                    else {
                                        return (
                                            <Route path={prop.path} component={prop.component} key={key} />
                                        );
                                    }
                                })}
                            </Switch>
                            {!(this.state.landing || this.state.package) && <Whatsapp />}
                        </div>
                        {!(this.state.landing || this.state.package) && <Footer />}
                    </div>
                </div>
            </>
        )
    }
}
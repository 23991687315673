import axios from 'axios';

class informationService {
    constructor() {
        this.gateway = process.env.REACT_APP_GATEWAY;
    }

    articles = (idCategory) => {
        return axios.get(this.gateway + '/info/api/articles/' + idCategory)
    }

    article = (idArticle) => {
        return axios.get(this.gateway + '/info/api/article/' + idArticle)
    }

    clap = (idArticle) => {
        const jsonParams = {
            idArticle: idArticle
        }
        return axios.post(this.gateway + '/info/api/clap', jsonParams)
    }
}

const instance = new informationService();

export default instance;
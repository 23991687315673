import React from 'react';

const AssembleIndividual = () => {

    return (
        <div>
            <div className="page-header" style={{ marginTop: "-30px" }}>
                <img src="img/headers/header-calculadora.png" alt="" />
                <a href="/#/select-guip">
                    <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                </a>
            </div>

            <div className="page-title">
                Solución domiciliar
            </div>
            <div className="page-text">
                Ahora elige el tipo de vivienda, seleccionando la imagen que más se parezca a tu hogar.
            </div>
            <br />

            <div className="news-n">

                <a href="/#/individual2/1">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/individual/rural.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Rural
                            </div>
                        </div>
                    </div>
                </a>

                <a href="/#/individual2/2">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/individual/urbana.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Urbana
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default AssembleIndividual;

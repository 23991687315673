import React from 'react';
import notificationService from '../../../services/notificationService'
import LoadingAnimation from "../../../components/animations/loadingAnimation";
import TextField from '@material-ui/core/TextField';

export default class NotificationValidate extends React.Component {
    state = {
        listImpacts: [],
        selectedImpact: null,
        file: null
    };

    componentDidMount() {
        const idHis = this.props.match.params.idHis;
        const idHisDet = this.props.match.params.idHisDet;
        this.fetchImpacts(idHis, idHisDet);

        (document.getElementById("imgMARN")).addEventListener('change', (event) => {
            this.setState({ file: event.target.files[0] })
        });
    }

    fetchImpacts(idHis, idHisDet) {
        notificationService.getImpacts(idHis, idHisDet)
            .then(data => {
                this.setState(
                    {
                        listImpacts: data.data
                    })
            })
            .catch(err => {
                console.log(err);
                this.setState({ listImpacts: [] })
                this.fetchImpacts(idHis, idHisDet);
                return null;
            });
    }

    selectImpact = (id) => {
        this.setState({ selectedImpact: id })
    }

    sendValidation = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const idHis = this.props.match.params.idHis;
            const idHisDet = this.props.match.params.idHisDet;
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const text = document.getElementById("details").value;
            var dateTimeEvent = document.getElementById("date-time-event").value;
            dateTimeEvent = dateTimeEvent.replace("T", " ");
            const timpactoid = this.state.selectedImpact;
            const fileToSend = this.state.file;

            if(idHis === null || idHisDet === null || latitude === null || longitude === null || text === null || timpactoid === null || fileToSend === null || dateTimeEvent === ""){
                alert("Debes completar toda la información")
            }else{

                const formData = new FormData();
                formData.append('idHis', idHis);
                formData.append('idHisDet', idHisDet);
                formData.append('latitud', latitude);
                formData.append('longitud', longitude);
                formData.append('timpactoid', timpactoid);
                formData.append('file', fileToSend);
                formData.append('texto', text);
                formData.append('fechaEvento', dateTimeEvent.concat(":00"));

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                notificationService.sendValidation(formData, config)
                    .then(data => {
                        console.log("Success");
                    })
                    .catch(err => {
                        console.log(err);
                    });
                window.location.replace("/#/notification-validate-sent/"+this.props.match.params.idMunicipality);
            }
        });
    }

    render() {
        return (
            <div>
                <div className="page-header" style={{ marginTop: "-30px" }}>
                    <a href="/#/notifications">
                        <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                    </a>
                </div>
                <br /><br /><br />
                <div className="page-title-notification">
                    Para ayudarnos a validar la notificación:
                </div>
                <br />
                <div className="page-text">
                    <b>Paso 1: Selecciona la imagen que mejor represente la situación que deseas reportar</b>
                </div>
                <div className="page-text">
                    {
                        (this.state.listImpacts.length > 0)
                            ?
                            (this.state.listImpacts.map((element, index) => {
                                return (
                                    <div className={"notification-validation-box " + ((element.timpactoid === this.state.selectedImpact) ? "selected-impact" : "")} key={index} onClick={() => this.selectImpact(element.timpactoid)}>
                                        <div className="notification-validation-box-img">
                                            <img className="validate" src={"img/notifications/impactos/" + element.url_imagen.substring(element.url_imagen.lastIndexOf('/') + 1)} alt="" />
                                        </div>
                                        <div className="notification-validation-box-content">
                                            <div className="notification-validation-box-text validate-selection">
                                                {element.timpacto}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }))
                            : <LoadingAnimation height={100} width={100} />
                    }
                    <div className="required">Selección obligatoria</div>
                </div>

                <div className="page-text">
                    <b>Paso 2: Toma una foto de la situación que deseas reportar</b>
                </div>
                <center>
                    <label htmlFor="imgMARN" className="file-form-notification">
                        <div className="div-file-form-notification">
                            <img src="img/buttons/cam.png" alt="" />
                        </div>
                        <span className="text-file-form-notification">Toma una foto</span>
                    </label>
                    <input id="imgMARN" style={{ visibility: "hidden" }} type={"file"}></input>
                    <div className="required" style={{textAlign: "left"}}>Foto obligatoria</div>
                    {(this.state.file) && (<><div><b>¡Foto agregada correctamente!</b></div>
                    <br/></>)}
                </center>                

                <div className="page-text">
                    <b>Paso 3: Bríndanos mas detalle</b>
                </div>

                <textarea id="details" type="text" className="calc-input textarea-form-notification" placeholder="Escribe aquí" />
                <div className="required">Campo obligatorio</div>

                <div className="page-text">
                    <b>Paso 4: Bríndanos la fecha y hora del suceso</b>
                </div>
                <TextField
                    id="date-time-event"
                    type="datetime-local"
                    defaultValue=""
                    className="date-form-notification"
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                <div className="required">Campo obligatorio</div>

                <br />
                <div style={{ textAlign: "center" }}>
                    <button className="accept-notifications button-form-notification" onClick={() => this.sendValidation()}>
                        Activar localización y enviar información
                        </button>
                </div>
            </div >
        );
    }
}



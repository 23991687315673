import React from 'react';
import { Steps } from 'intro.js-react';
import "intro.js/introjs.css";
//import "./index.css";
import logo from '../../../src/assets/images/guip-logo.svg';




export default class Main extends React.Component {

  constructor(props) {
    super(props);
    const type = this.props.match.params.idType;

    this.state = {
      type: type,
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".step2",
          position: "right",
          intro: "¡Experiencia paso a paso para sacar de su empaque la maqueta!",
          nextLabel: "Ver siguiente"
        },
        {
          element: ".step3",
          position: "left",
          intro: "¡Experiencia paso a paso para armar la maqueta del prototipo!"
        }
      ]
    };
  }

  
  componentDidMount() {
    function sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }
    
    const init = async () => {
      await sleep(2000);
      this.toggleSteps();
    }
    
    init();
  }

  
  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
    } = this.state;

    return (
      <>
        <div>
          <div className="landing-header unboxing-header">
          </div>
          <div className="landing-content unboxing">
            <div className="landing-ws" style={{ fontSize: "12px" }}>
              <img src="img/landing/ws.svg" alt="" />
              <span>&nbsp;&nbsp;Escríbenos: {process.env.REACT_APP_WHATSAPP}</span>
            </div>
            <div className="logo-video" style={{ marginTop: "-33px" }}>
              <div className="logo-message">
                <a href="/">
                  <img src={logo} alt="homepage" className="dark-logo landing" style={{ width: "100px" }} />
                </a>
                <div className="logo-name landing" style={{ fontSize: "12px" }}>
                  <b>Agua caída del cielo</b>
                  <br />
                  Sistema de cosecha de aguas lluvias en El Salvador
                  <br />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "45px" }}>
              <center>
                <h4>
                <center>
                  <img src={"img/package/"+((this.state.type === "1")? "rural": "productivo")+".png"} alt="" width="70px"/>
                </center>
                  <br/>
                  <b>¡Aprender más del sistema {(this.state.type === "1") ? "Domiciliar Rural" : "Productivo"} que Güip te ofrece!</b>
                </h4>
              </center>
              <br />
              <div className="unboxing-main-panel">
                <div className="unboxing-main-option opt1 step2">

                  <div style={{ display: "inline-block" }}>
                    <a href={"/#/package-unboxing/" + this.state.type}>
                      <div>
                        <img src={"img/assemble/individual/renders/render-T1-2.jpg"} alt="" className="unboxing-img" />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <button className="unboxing-buttons">
                          ¡Unboxing!
                      </button>
                      </div>
                    </a>
                  </div>

                </div>

                <div className="unboxing-main-option opt2">
                  <div style={{ display: "inline-block" }}>
                    <div style={{ height: "190px" }}>
                      {/*  */}
                    </div>
                    <div></div>
                  </div>
                </div>

                <div className="unboxing-main-option opt3 step3">


                  <div style={{ display: "inline-block" }}>
                    <a href={"/#/package-assemble/" + this.state.type}>
                      <div>
                        <img src={"img/assemble/productive/renders/render-T4-3.jpg"} alt="" className="unboxing-img" />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <button className="unboxing-buttons">
                          ¡Armado del prototipo!
                      </button>
                      </div>
                    </a>
                  </div>

                </div>
              </div>
              <br /><br />
              <center>
                <div className="step1">-Selecciona una de las opciones-</div>
              </center>

              <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onExit={this.onExit}
                options={
                  {
                    nextLabel: "Ver siguiente",
                    prevLabel: "Ver anterior",
                    doneLabel: "¡Entendido!",
                    hidePrev: true,
                    hideNext: false, //done
                    showStepNumbers: false,
                    showBullets: false
                  }
                }
              />
            </div>

            <div className="unboxing-bottom" style={{ marginTop: "250px" }}>
              <div className="landing-more-information">
                <div className="landing-more-information-img1">
                  <img src="img/landing/left.svg" alt="" />
                </div>
                <div className="landing-more-information-text">
                </div>
                <div className="landing-more-information-img2">
                  <img src="img/landing/right.svg" alt="" />
                </div>
              </div>

              <div className="landing-footer">
                <div>
                  <div>
                    <img src="img/guip-style/footer-logotipo.svg" alt="" style={{ marginBottom: "10px" }} />
                  </div>
                  Güip - Sistema inteligente de captación de agua lluvia
              </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

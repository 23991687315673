import React from 'react'
import SoundAnimation from '../../components/animations/soundAnimation'

export default class SelectVersion extends React.Component {

    state = {
        voice: null,
        synth: null
    };

    componentDidMount() {
        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
    }

    go = () => {
        const phone = document.getElementById("phone").value;
        localStorage.setItem('phone', phone);
        window.location.replace("/#/v2/heroes-logged-in");
    }

    render() {
        return (
            <div>
                <br /><br /><br />
                <br /><br /><br />
                <center>
                    <div onClick={() => this.speak("Únete a las personas de tu comunidad que colaboran en cuidar el agua. Pide ayuda a alguien para ingresar tu número de teléfono")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <br />

                <div className="page-title">
                    Héroes del agua
                </div>

                <div className="page-text">
                    ¡Comparte tus logros y obtén recompensas!
                    <br />
                    Mira cómo lo han hecho otros.
                </div>
                <br />
                <div className="header-section-home">
                    <hr />
                    <span>
                        Novedades de la comunidad
                    </span>
                </div>
                <center>Aún no hay experiencias compartidas.</center>

                <br /><br />


                <div className="page-text">
                    ¡Comparte tus logros y obtén hasta un 30% de descuento!
                    <br />
                    Para hacerlo, introduce tu número de celular toca el botón de iniciar sesión.
                </div>

                <div className="input-box">
                    <span className="prefix">(+503)</span>
                    <input type="number" id="phone" placeholder="" />
                </div>

                <center>
                    <br />
                    <img src="img/guip-style/heroes/gota.svg" width="40%" alt="" />
                    <br />
                </center>

                <a href="#/v2/heroes-logged-in">
                    <center>
                        <button className="accept-notifications comparte" onClick={() => this.go()}>
                            <img src="img/guip-style/heroes/login.svg" width="20%" alt="" />
                            <br />
                            Iniciar sesión
                        </button>
                    </center>
                </a>

            </div>
        );
    }
}
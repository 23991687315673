import React from 'react';
import ReactPlayer from "react-player"

const Productive = () => {

    return (
        <div>
            <div className="page-header" style={{ marginTop: "-30px" }}>
                <img src="img/tutorial/productive/productivo.png" alt="" />
                <a href="/#/tutorial">
                    <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                </a>
            </div>

            <div className="news-title">
            Solución Productiva
            </div>

            <div className="page-text">
            Diseñada para la ciudad y contextos urbanos para colectar y cosechar agua lluvia.
            Esta solución se ha desarrollado contemplando diferentes tipos de materiales que tienen las vivienda del área urbana, tanto en techos, pisos y paredes, que son los elementos principales para la fijación e instalación del sistema.
            <br/>
            A continuación, te compartimos mediante un vídeo el sistema de esta solución mediante un recorrido de una maqueta demostrativa con una vivienda modelo como ejemplo para tu comprensión.
            </div>

            <br />

            <div className="article-box">
                <div className="article-box-img">
                    <ReactPlayer width="100%" url="https://youtu.be/zwnHNQwodb8" />
                </div>
                <div className="article-box-content">
                    {/* <div className="article-box-text">Pasos</div> */}
                    <div className="article-box-text">Demostración</div>
                    <div className="article-box-detail tutorial">Duración: 30 segundos <br/> Dificultad: Fácil</div>
                </div>
            </div>

            <div className="article-box">
                <div className="article-box-img">
                    <img src="img/tutorial/productive/productivo.gif" alt="" />
                </div>
                <div className="article-box-content">
                    {/* <div className="article-box-text">Pasos</div> */}
                    <div className="article-box-text">Figura 1. Ilustración de la instalación del Sistema Productivo..</div>
                    <div className="article-box-detail tutorial">Nota: Para más detalles y guía paso a paso, te invitamos a descargar el PDF del Manual de Instalación de esta solución.</div>
                </div>
            </div>


            <div className="article-box">
                <div className="article-box-content">
                    <div className="article-box-detail tutorial"><b>¡Te invitamos para ver paso a paso como se instala el sistema a descargar el Manual Productivo.</b></div>
                    <a href="/manuales/manual_productivo.pdf" target="_blank" rel="noopener noreferrer">
                        <button className="download-pdf">
                            <img src="img/buttons/pdf.svg" alt="" />
                            Descargar PDF
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Productive;
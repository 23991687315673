import React from 'react';

import opt01 from '../../../assets/images/options/reel/mainOpt01.svg';
import opt02 from '../../../assets/images/options/reel/mainOpt02.svg';
import opt03 from '../../../assets/images/options/reel/mainOpt03.svg';
import opt04 from '../../../assets/images/options/reel/mainOpt04.svg';
import opt05 from '../../../assets/images/options/reel/mainOpt05.svg';
import Learn from '../Learn.jsx';
import MainTutorial from '../tutorial/mainTutorial.jsx';
import SelectGUIP from '../select-guip/SelectGuip.jsx';
import Notifications from '../notifications/Notifications.jsx';
import Share from '../Share.jsx';
import informationService from '../../../services/informationService'
import notificationService from '../../../services/notificationService'
import {
    getUserSubscription
} from "../../../pushNotifications/push-notifications";
import http from "../../../util/http";
import Cookies from 'universal-cookie';
import ReactPlayer from "react-player"
import LoadingAnimation from '../../../components/animations/loadingAnimation'

var optionsReel = [
    {
        path: '/learn',
        name: 'Silabario\ndel agua',
        img: opt01,
        component: Learn
    },
    {
        path: '/notifications',
        name: 'Alertas\ndel clima',
        img: opt04,
        component: Notifications
    },
    {
        path: '/tutorial',
        name: 'Conoce\n e instala Güip',
        img: opt02,
        component: MainTutorial
    },
    {
        path: '/select-guip',
        name: 'Soluciones\ny precios',
        img: opt03,
        component: SelectGUIP
    },
    {
        path: '/share/:id',
        name: 'Héroes\ndel Agua',
        img: opt05,
        component: Share
    }
]

export default class Starter extends React.Component {
    state = {
        todayNotifications: [],
        todayNotificationsLoading: false,
        articles: [],
        articlesLoading: false,
        subscription: null,
        ga: null
    };

    isPushNotificationSupported = () => {
        return "serviceWorker" in navigator && "PushManager" in window;
    }

    getNotificationsSubscription = async () => {
        if (this.isPushNotificationSupported()) {

            const subscription = await getUserSubscription();
            http.post("/subscription", subscription)
                .then((response) => {
                    this.setState({ subscription: response.id })
                    this.notificationsDetail(this.state.subscription)
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            const cookies = new Cookies();
            notificationService.checkSubscription(cookies.get('_ga'))
                .then(data => {
                    if (data.data) {
                        this.setState({ ga: cookies.get('_ga') });
                        this.notificationsDetail(this.state.ga);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ list: [] })
                    return null;
                });
        }
    }

    notificationsDetail = (subscription) => {
        this.setState({
            todayNotificationsLoading: true
        })
        notificationService.detailList(subscription)
            .then(data => this.setState(
                {
                    todayNotifications: data.data.todayNotifications,
                    todayNotificationsLoading: false
                }))
            .catch(err => {
                console.log(err);
                this.setState({
                    todayNotifications: [],
                    todayNotificationsLoading: false
                })
                return null;
            });
    }

    getArticles = () => {
        this.setState({
            articlesLoading: true
        })
        informationService.articles(1)
            .then(data => this.setState({
                articles: data.data,
                articlesLoading: false
            }))
            .catch(err => {
                console.log(err);
                this.setState({
                    articles: [],
                    articlesLoading: false
                })
            });
    }

    componentDidMount() {
        this.getNotificationsSubscription();
        this.getArticles();
    }

    render() {
        return (
            <div>
                <div className="header-home">
                    <div className="reel-hidden-scroll">
                        <div className="reel">
                            {
                                optionsReel.map((prop, index) => {
                                    return (
                                        <div className="option" key={index}>
                                            <a href={"#" + prop.path}>
                                                <img src={prop.img} alt="" />
                                                <div className="option-name">
                                                    {prop.name.split("\n").map(function (item, idx) {
                                                        return (
                                                            <span key={idx}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="page-title">
                    Sobre el proyecto
                </div>
                <div className="page-text title">
                    <b>Guip es un sistema de cosecha de aguas lluvias de bajo costo y de fácil instalación para todas las personas.</b>
                </div>
                <br />
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://youtu.be/8Qd7-nvSs_k" />
                    </div>
                </div>
                <div className="page-text">
                    <b>Nuestro propósito</b>  es brindar una solución alternativa para la población que no tiene acceso al agua potable o sufren problemas de intermitencia.
                </div>
                <br /><br />

                <div className="header-section-home">
                    <hr />
                    <span>
                        Notificaciones nuevas
                    </span>
                </div>
                {
                    (this.state.todayNotifications.length > 0)
                        ?
                        (this.state.todayNotifications.map((element, index) => {
                            return (
                                <a href={"#/notificationDetail/" + element.idHis + "/" + element.idHisDet} key={index}>
                                    <div className="notification-box" key={index}>
                                        <div className="notification-box-img">
                                            <img src={"img/notifications/notification0" + element.idArea + ".png"} alt="" />
                                        </div>
                                        <div className="notification-box-content">
                                            <div className="notification-box-type"
                                                style={{ backgroundColor: element.notificationBackground, color: element.notificationFontColor }}
                                            >{element.type}</div>
                                            <div className="notification-box-time">{element.date}</div>
                                            <div className="notification-box-text">
                                                {(element.title[0].length > 100) ? element.title[0].substr(0, 100) + "..." : element.title[0]}
                                            </div>
                                            <div className="notification-box-detail">
                                                Ver detalle
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )
                        }))
                        : !(this.state.articles.todayNotificationsLoading) ? <center>Aún no hay notificaciones nuevas para este día.</center> : <LoadingAnimation height={100} width={100} />
                }
                <br />
                <div>
                Para poder recibir las notificaciones del clima, ve a la sección <b>“Alertas del clima”</b>
                </div>
                <br />

                <div className="header-section-home">
                    <hr />
                    <span>
                        Información útil para tener agua todos los días
                    </span>
                </div>
                {(this.state.articles.length > 0)
                    ?
                    (this.state.articles.map((element, index) => {
                        return (
                            <a href={"/#/homeDetail/" + element.id} key={index}>
                                <div className="article-box" key={index}>
                                    <div className="article-box-img">
                                        <img src={((element.imgUrl === null) ? "img/articles/default.jpg" : element.imgUrl.substr(1))} alt="" />
                                    </div>
                                    <div className="article-box-content">

                                        <div className="article-box-claps">
                                            <img src="img/buttons/clap.svg" alt="" />
                                            <span>
                                                {element.claps} personas han aplaudido esto
                                            </span>
                                        </div>
                                        <div className="article-box-text">{element.title}</div>
                                        <div className="article-box-detail">Leer más</div>
                                    </div>
                                </div>
                            </a>
                        )
                    }))
                    : !(this.state.articles.articlesLoading) ? <center>No hay artículos disponibles.</center> : <LoadingAnimation height={100} width={100} />
                }

                <br />
                
                <div className="article-box">
                    <div className="article-box-content">
                        <center>
                            <div className="article-box-detail tutorial"><b>¡Descarga tu manual para instalar el sistema domiciliar rural!</b></div>
                            <a href="/manuales/manual_rural_guip2.pdf" target="_blank" rel="noopener noreferrer">
                                <button className="accept-notifications comparte">
                                    <img src="img/guip-style/heroes/descargar.svg" width="20%" alt="" />
                                    <br />
                                    Descargar manual
                                </button>
                            </a>
                        </center>
                    </div>
                </div>
            </div>
        )
    }
}

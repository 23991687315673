import React, {useState, useEffect} from 'react';
const Whatsapp = () =>{

  const [showWhatsapp, setShowWhatsapp] = useState(false)

  useEffect(()=>{
    window.addEventListener('scroll', checkScrollTop)
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop)
    }
  })

  const checkScrollTop = () => {
    if (!showWhatsapp && window.pageYOffset > (window.innerHeight+100)){
        setShowWhatsapp(true)
    } else if (showWhatsapp && window.pageYOffset <= (window.innerHeight+100)){
        setShowWhatsapp(false)
    }
  };

  const whatsapp = () =>{
    const number = process.env.REACT_APP_WHATSAPP;
    const message = "Contacto desde Guip";
    const url = 'https://api.whatsapp.com/send?phone='
        + number
        + '&text='
        + encodeURIComponent(message)
    window.open(url, "_blank")
  };

  return (
        <img src="img/social/ws.png" alt="" className="ws" onClick={()=>whatsapp()} id="ws-btn-fixed" style={{height: 40, display: showWhatsapp ? 'flex' : 'flex'}}/>
  );
}

export default Whatsapp;
import React from 'react'
import SoundAnimation from '../../components/animations/soundAnimation'
import notificationService from '../../services/notificationService'

export default class SelectVersion extends React.Component {

    state = {
        voice: null,
        synth: null,
        file: null,
        achievements: new Set()
    };

    componentDidMount() {
        (document.getElementById("imgExperience")).addEventListener('change', (event) => {
            this.setState({ file: event.target.files[0] })
        });

        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
    }

    share = () => {
        const fileToSend = this.state.file;
        let fileBase64 = '';
        const text = document.getElementById("details").value;
        // console.log(this.state.achievements);
        if (this.state.achievements.size === 0 || fileToSend === null || text === "") {
            alert("Debes completar todos los pasos y campos")
        } else {

            this.getBase64(fileToSend, (result) => {
                fileBase64 = result;
                console.log(fileBase64);

                notificationService.shareExperience(localStorage.getItem('phone'),  [...this.state.achievements].join(', '), fileBase64, text)
                    .then(data => {
                        this.setState({ file: null })
                        document.getElementById('details').value = "";
                        alert("Gracias por compartir tu experiencia")
                        window.location.replace("/#/v2/heroes");
                    })
                    .catch(err => {
                        console.log(err);
                        return null;
                    });
            });
        }
    }

    getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    addAchievement = (achievement) => {
        this.setState({ achievements: this.state.achievements.add(achievement) })
    }

    render() {
        return (
            <div>
                <br /><br /><br />
                <br /><br />
                <center>
                    <div onClick={() => this.speak("Si quieres realizar alguno de los retos, pide ayuda a alguien de tu confianza para que te ayude a llenar el formulario para que otros puedan conocer tu logro y obtener tu recompensa como héroe del agua.")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <br />

                <div className="page-title">
                    Héroes del agua
                </div>

                <div className="page-text">
                    <span>
                        <b>¡Bienvenido!</b>
                    </span>
                    <br />
                    <br />
                    Puedes acumular gotas al completar alguno de los siguientes retos y compartir tu experiencia.
                    <ul>
                        <li>Instala tu Güip</li>
                        <li>Limpia tu tanque</li>
                        <li>Ayuda a otra persona a utilizar la página de Güip</li>
                        <li>Ayuda a otra persona con la instalación de su sistema Güip</li>
                    </ul>

                    <br />
                    <b>
                        Si ya realizaste alguno de estos retos, completa la siguiente información:
                    </b>
                    <br /><br />
                    <b>
                        Paso 1:
                    </b>
                    <br />
                    Selecciona el reto que realizaste (puede ser 1 o más de 1).
                </div>
                <br />
                <div className="page-text">
                    <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                        <li style={{ display: 'block' }}>
                            <input type="checkbox" id="cbox2" value="second_checkbox" onClick={() => this.addAchievement("Instalar mi Güip")} />
                            <label for="cbox2" style={{ display: "contents" }}>&nbsp;Instalar mi Güip</label>
                        </li>
                        <li style={{ display: 'block' }}>
                            <input type="checkbox" id="cbox2" value="second_checkbox" onClick={() => this.addAchievement("Limpiar mi tanque")} />
                            <label for="cbox2" style={{ display: "contents" }}>&nbsp;Limpiar mi tanque</label>
                        </li>
                        <li style={{ display: 'block' }}>
                            <input type="checkbox" id="cbox2" value="second_checkbox" onClick={() => this.addAchievement("Ayudar a otra persona a utilizar la página de Güip")} />
                            <label for="cbox2" style={{ display: "contents" }}>&nbsp;Ayudar a otra persona a utilizar la página de Güip</label>
                        </li>
                        <li style={{ display: 'block' }}>
                            <input type="checkbox" id="cbox2" value="second_checkbox" onClick={() => this.addAchievement("Ayudar a otra persona con la instalación su sistema Güip")} />
                            <label for="cbox2" style={{ display: "contents" }}>&nbsp;Ayudar a otra persona con la instalación su sistema Güip</label>
                        </li>

                    </ul>
                </div>

                <div className="page-text">
                    <b>
                        Paso 2:
                    </b>
                    <br />
                    Toma una foto del resultado
                </div>
                <br />

                <center>
                    {/*                     <label htmlFor="imgExperience" className="file-form-notification">
                        <div className="div-file-form-notification">
                            <img src="img/buttons/cam.png" alt="" />
                        </div>
                        <span className="text-file-form-notification">Toma una foto de tu Güip</span>
                    </label> */}
                    <button className="accept-notifications comparte">
                        <label htmlFor="imgExperience">
                            <img src="img/guip-style/heroes/foto.svg" width="20%" alt="" />
                            <br />
                            ¡Toma una foto de tu Güip!
                        </label>
                    </button>

                    <input id="imgExperience" style={{ visibility: "hidden" }} type={"file"}></input>
                    <div className="required" style={{ textAlign: "left" }}>Foto obligatoria</div>
                    {(this.state.file) && (<><div><b>¡Foto agregada correctamente!</b></div>
                        <br /></>)}
                </center>

                <div className="page-text">
                    <b>
                        Último 3:
                    </b>
                    <br />
                    Cuéntanos en la siguiente caja, cómo fue tu experiencia realizando el reto.
                    {/*                     <center>
                        <img src="img/guip-style/heroes/microphone.png" alt="" width="10%" />
                    </center> */}
                    <br />
                    <br />
                </div>
                <textarea id="details" type="text" className="calc-input textarea-form-notification" placeholder="Escribe aquí" />
                <div className="required">Campo obligatorio</div>

                <center>
                    <button className="accept-notifications comparte" onClick={() => this.share()}>
                        <img src="img/guip-style/heroes/comparte.svg" width="20%" alt="" />
                        <br />
                        Comparte
                    </button>
                </center>

                <br /><br />
                <center>
                    <a href="#/v2/heroes">
                        <img src="img/arrow-back.svg" width="20%" alt="" />
                        <br />
                        Regresar
                    </a>
                </center>

            </div>
        );
    }
}
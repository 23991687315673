import React from 'react';
import SoundAnimation from '../../components/animations/soundAnimation'
import TurnUpVolumne from '../../components/animations/TurnUpVolumne'

export default class SelectVersion extends React.Component {

    state = {
        show: true,
        voice: null,
        synth: null
    };

    hide = () => {
        this.setState({ show: false });
    }

    componentDidMount() {
        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
      }

    render() {
        return (
            <div>
                <center>
                    <div onClick={() => this.speak("Hola, bienvenido a Güip. Toca la imagen de la oreja si quieres escuchar la información.  Si quieres leer la información toca la imagen del libro.")}>
                        <SoundAnimation height={100} width={100} />
                    </div>
                </center>
                <div style={{ textAlign: 'center' }}>
                    <div>
                        <a href={"#/home"}>
                            <button className="select-version opt-1">
                                <span>
                                    <img src="img/selectVersion/2.svg" width="15%" alt="" />
                                </span>
                                <br />
                                Quiero leer
                            </button>
                        </a>
                    </div>
                    <div>
                        <a href={"#/v2/learn"}>
                            <button className="select-version opt-2">
                                <span>
                                    <img src="img/selectVersion/1.svg" width="15%" alt="" />
                                </span>
                                <br />
                                Quiero escuchar
                            </button>
                        </a>
                    </div>
                    <div>

                        <button className="select-version opt-3">
                            <span>
                                <img src="img/selectVersion/3.svg" width="15%" alt="" />
                            </span>
                            <br />
                            Quiero donar
                        </button>

                    </div>
                </div>

                {this.state.show && (<div onClick={() => this.hide()}>
                    <div style={{ position: "fixed", top: "0px", zIndex: 999999, height: "100000px", background: "rgba(0,0,0,0.5)", left: "0px", paddingTop: "67px", width: "100%" }}>
                        <TurnUpVolumne height={400} width={400} />
                    </div>
                </div>)}
            </div>
        );
    }
}
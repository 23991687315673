import React from 'react';
import informationService from '../../services/informationService'
import SoundAnimation from '../../components/animations/soundAnimation'
var parse = require('html-react-parser');

export default class HomeDetail extends React.Component {
    state = {
        title: null,
        detail: "",
        claps: null,
        idArticle: null,
        idImg: null,
        imgUrl: null,
        idCategory: null,
        voice: null,
        synth: null
    };

    clap = () => {
        informationService.clap(this.state.idArticle);
        alert("Gracias por aplaudir la publicación")
    }

    ws = () => {
        const href = window.location.href;
        this.copyToClipboard(href);
        alert("El link del artículo ha sido copiado, ahora puedes pegarlo en WhatsApp");
    }

    copyToClipboard = (string) => {
        let textarea;
        let result;

        try {
            textarea = document.createElement('textarea');
            textarea.setAttribute('readonly', true);
            textarea.setAttribute('contenteditable', true);
            textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
            textarea.value = string;

            document.body.appendChild(textarea);

            textarea.focus();
            textarea.select();

            const range = document.createRange();
            range.selectNodeContents(textarea);

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            textarea.setSelectionRange(0, textarea.value.length);
            result = document.execCommand('copy');
        } catch (err) {
            console.error(err);
            result = null;
        } finally {
            document.body.removeChild(textarea);
        }

        if (!result) {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
            result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
            if (!result) {
                return false;
            }
        }
        return true;
    }




    componentDidMount() {
        this.setState(
            {
                idArticle: this.props.match.params.idArticle,
                idImg: this.props.match.params.idImg
            })

        informationService.article(this.props.match.params.idArticle)
            .then(data => {
                this.setState(
                    {
                        title: data.data.title,
                        detail: data.data.description,
                        claps: data.data.claps,
                        imgUrl: data.data.imgUrl.substr(1),
                        idCategory: data.data.idCategory
                    })
            })
            .catch(err => {
                console.log(err);
                this.setState({ detail: null })
                return null;
            });

        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
    }

    render() {
        return (
            <div>
                <div className="page-header" style={{ marginTop: "-30px" }}>
                    <img src={((this.state.imgUrl === null) ? "img/articles/default.jpg" : this.state.imgUrl)} alt="" />
                    <a href={(this.state.idCategory === 1) ? "/#/v2/learn" : (this.state.idCategory === 2) ? "/#/learn" : "/#/share"}>
                        <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                    </a>
                </div>

                <center>
                    <div onClick={() => this.speak(this.state.detail)}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <div className="page-title-article">
                    {this.state.title}
                </div>
                <br />
                <div className="page-text-article">

                    {parse((this.state.detail))}

                </div>

                <center>
                    <button type="button" className="styled-button" onClick={() => this.ws()}>
                        <div className="div-styled-button">
                            <img src="img/social/ws.png" alt="" />
                        </div>
                        <span className="text-styled-button">Link para compartir</span>
                    </button>
                    Luego de dar clic al botón, el link estará copiado para que puedas dirigirte a Whatsapp y compartirlo.
                </center>
            </div >
        );
    }
}



import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
//import Sponsors from '../../views/options/Sponsors.jsx';
import About from '../../views/options/About.jsx';
import Contact from '../../views/options/Contact.jsx';
var otherInformation = [
    {
        path: '/about',
        name: 'Sobre el proyecto',
        reel: false,
        moreInformation: true,
        component: About
    },
/*     {
        path: '/sponsors',
        name: 'Aliados',
        reel: false,
        moreInformation: true,
        component: Sponsors
    }, */
    {
        path: '/contact',
        name: 'Contacto',
        reel: false,
        moreInformation: true,
        component: Contact
    }
]

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                    <div className="footer-img">
                    <img src="img/guip-style/footer-version-basica.svg" alt="" />
                    </div>
                    <div className="footer-content">
                        <div className="footer-title">
                            <div className="footer-logotipo">
                                <img src="img/guip-style/footer-logotipo.svg" alt="" style={{width:'15%'}}/>
                            </div>
                            <br/>
                            Güip - Guía de instalación para las personas
                        </div>
                        <Nav id="sidebarnav" className="footer-other-information">
                            {otherInformation.map((prop, key) => {
                                return (
                                    <li key={key}>
                                        <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
                                            <img src={"img/sidebar/genericOpt02-footer.svg"} alt={prop.path} style={{ padding: "0px 25px 0px 20px" }} />
                                            <span className="hide-menu">{prop.name}</span>
                                        </NavLink>
                                    </li>
                                );

                            })}
                        </Nav>
                    </div>
            </div>
            
        </footer>
    );
}
export default Footer;

import React from 'react';
import SoundAnimation from '../../components/animations/soundAnimation'
import ArrowRightAnimation from '../../components/animations/arrowRightAnimation'
import SwipeVertical from '../../components/animations/SwipeVertical'
import SwipeHorizontal from '../../components/animations/SwipeHorizontal'
import ReactPlayer from "react-player"

export default class SelectVersion extends React.Component {

    state = {
        show1: false,
        show2: false,
        voice: null,
        synth: null
    };

    componentDidMount() {
        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.log(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message, animate = true) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);

        if (animate) {
            this.setState({ show1: true });
            setTimeout(
                function () {
                    this.setState({ show1: false });
                    this.setState({ show2: true });
                    setTimeout(
                        function () {
                            this.setState({ show2: false });
                        }
                            .bind(this),
                        3000
                    );
                }
                    .bind(this),
                10000
            );
        }
    }

    speakPreventDefault = (e, message) => {
        e.preventDefault()
        this.speak(message, false)
    }

    hide = () => {
        this.setState({ show1: false, show2: false });
    }

    render() {
        return (
            <div>
                <br /><br /><br />
                <br /><br /><br />
                <br />
                <center>
                    <div onClick={() => this.speak("Aquí conocerás qué es Güip, la importancia del agua lluvia y sus usos. Para mirar esta información, desliza tu dedo de arriba a abajo o de izquierda a derecha por la pantalla como se muestra en la animación.")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <br />

                <div className="page-title">
                    ¿Qué es Güip?
                </div>

                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://youtu.be/8Qd7-nvSs_k" />
                    </div>
                </div>

                <br />
                <div className="page-title">
                    Silabario del agua
                </div>

                <div className="reel-hidden-scroll">
                    <div className='arrow-articles-horizontal'>
                        <ArrowRightAnimation />
                    </div>

                    <div className="reel" style={{ height: "565px" }}>
                        <div className="header-section-home articles-horizontal">
                            <a href={"/#/v2/silabarioV2Detail/4"} key="1">
                                <div className="article-box" key="1">
                                    <div className="article-box-img">
                                        <img src="img/articles/default.jpg" alt="" style={{ height: "250px" }} />
                                    </div>
                                    <div className="article-box-content">

                                        <div className="article-box-claps">
                                            <img src="img/buttons/clap.svg" alt="" />
                                            <span>
                                                10 personas han aplaudido esto
                                            </span>
                                        </div>
                                        <div className="article-box-text">Artículo sobre la importancia del agua</div>
                                        <div className="article-box-detail">Leer más</div>
                                    </div>
                                    <div onClick={(e) => this.speakPreventDefault(e, "Aquí encontrarás información sobre el agua. Para conocer todo lo que dice puedes pedir ayuda a alguien")}>
                                        <SoundAnimation height={100} width={100} />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="header-section-home articles-horizontal">
                            <a href={"/#/v2/silabarioV2Detail/2"} key="2">
                                <div className="article-box" key="1">
                                    <div className="article-box-img">
                                        <img src="img/articles/img8.jpg" alt="" style={{ height: "250px" }} />
                                    </div>
                                    <div className="article-box-content">

                                        <div className="article-box-claps">
                                            <img src="img/buttons/clap.svg" alt="" />
                                            <span>
                                                10 personas han aplaudido esto
                                            </span>
                                        </div>
                                        <div className="article-box-text">Artículo sobre cosecha de agua</div>
                                        <div className="article-box-detail">Leer más</div>
                                    </div>
                                    <div onClick={(e) => this.speakPreventDefault(e, "Aquí encontrarás información sobre cosecha de agua. Para conocer todo lo que dice puedes pedir ayuda a alguien")}>
                                        <SoundAnimation height={100} width={100} />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="header-section-home articles-horizontal">
                            <a href={"/#/v2/silabarioV2Detail/9"} key="2">
                                <div className="article-box" key="1">
                                    <div className="article-box-img">
                                        <img src="img/articles/img11.jpg" alt="" style={{ height: "250px" }} />
                                    </div>
                                    <div className="article-box-content">

                                        <div className="article-box-claps">
                                            <img src="img/buttons/clap.svg" alt="" />
                                            <span>
                                                10 personas han aplaudido esto
                                            </span>
                                        </div>
                                        <div className="article-box-text">Artículo sobre almacenamiento del agua</div>
                                        <div className="article-box-detail">Leer más</div>
                                    </div>
                                    <div onClick={(e) => this.speakPreventDefault(e, "Aquí encontrarás información sobre el tiempo que puedes almacenar el agua. Para conocer todo lo que dice puedes pedir ayuda a alguien")}>
                                        <SoundAnimation height={100} width={100} />
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=lVRurx7jgqg" />
                    </div>
                </div>
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=SD8NzRZchrg" />
                    </div>
                </div>

                {this.state.show1 && (<div onClick={() => this.hide()}>
                    <div style={{ position: "fixed", top: "0px", zIndex: 999999, height: "100000px", background: "rgba(0,0,0,0.5)", left: "0px", paddingTop: "128px", paddingLeft: "-62px" }}>
                        <SwipeVertical height={500} width={500} />
                    </div>
                </div>)}

                {this.state.show2 && (<div onClick={() => this.hide()}>
                    <div style={{ position: "fixed", top: "0px", zIndex: 999999, height: "100000px", background: "rgba(0,0,0,0.5)", left: "0px", paddingTop: "128px", paddingLeft: "-62px" }}>
                        <SwipeHorizontal height={500} width={500} />
                    </div>
                </div>)}


            </div>
        );
    }
}
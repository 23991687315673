import React from 'react';
import GuipAnimation from './guipAnimation'

export default class SplashScreen extends React.Component {
    render() {
        return (
            <div style={this.props.style} className="guip-splash-screen">
                <GuipAnimation height={300} width={300} />
                <center>
                    <div style={{color: "#fff", fontSize: "16px"}}>Cargando...</div>
                </center>
            </div>
        );
    }
}
import React from "react";
import Lottie from "react-lottie";
/* import animationData from "./bkp/turnUpSound2.json"; */
import animationData from "./json/mano-3seg.json";

function SoundAnimation(props) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /* return <Lottie options={defaultOptions} height={props.height} width={props.width} />; */
  return (<div style={{marginLeft: '-23%'}}>
    <Lottie options={defaultOptions} height={props.height} width={props.width} style={{display: '-webkit-inline-box'}}/><img src="img/sound.svg" alt="" style={{width: '15%'}}/>
  </div>)
}

export default SoundAnimation;
import React from 'react';
import "intro.js/introjs.css";
import logo from '../../../src/assets/images/guip-logo.svg';
import ReactPlayer from "react-player"

export default class Assemble extends React.Component {

  constructor(props) {
    super(props);
    const type = this.props.match.params.idType;

    this.state = {
      type: type
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        <div>
          <div className="landing-header unboxing-header">
          </div>
          <div className="landing-content unboxing">
            <div className="landing-ws" style={{ fontSize: "12px" }}>
              <img src="img/landing/ws.svg" alt="" />
              <span>&nbsp;&nbsp;Escríbenos: {process.env.REACT_APP_WHATSAPP}</span>
            </div>
            <div className="logo-video" style={{ marginTop: "-33px" }}>
              <div className="logo-message">
                <a href="/">
                  <img src={logo} alt="homepage" className="dark-logo landing" style={{ width: "100px" }} />
                </a>
                <div className="logo-name landing" style={{ fontSize: "12px" }}>
                  <b>Agua caída del cielo</b>
                  <br />
                  Sistema de cosecha de aguas lluvias en El Salvador
                  <br />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "56px" }}>
                <h4>
                  <center>
                    <img src={"img/package/"+((this.state.type === "1")? "rural": "productivo")+".png"} alt="" width="70px"/>
                  </center>
                  <br/>
                  <br/>
                  <b>Prototipo {(this.state.type === "1")? "Domiciliar Rural": "Productivo"}</b>
                </h4>
                <div style={{ float: "right", marginTop: "-60px" }}>
                <a href={"/#/package-unboxing/"+this.state.type}>
                  <button className="unboxing-buttons-navigate">
                    ¡Unboxing!
                  </button>
                </a>
                </div>
              <br />
              <div className="unboxing-main-panel video-package">
                <div className="unboxing-main-option-video video">

                  <div style={{ display: "inline-block", width: "100%" }}>
                    <a href={"/#/package-unboxing/" + this.state.type}>
                      <div>
                        <center>
                        <ReactPlayer className="video-unboxing" url={(this.state.type === "1")? "https://www.youtube.com/watch?v=UetMdOCGYJg": "https://www.youtube.com/watch?v=KiApJC0xNdQ"} />
                        </center>
                      </div>
                    </a>
                  </div>

                </div>
              </div>
              <br /><br />
              <center>
                <div className="step1">
                  ¡Arma la maqueta del prototipo {(this.state.type === "1") ? "Domiciliar Rural" : "Productivo"},
                  <br />
                  sigue el paso a paso de este vídeo que hemos creado para brindarte una experiencia divertida y didáctica!
                </div>
              </center>
            </div>

            <div className="unboxing-bottom" style={{ marginTop: "300px" }}>
              <div className="landing-more-information">
                <div className="landing-more-information-img1">
                  <img src="img/landing/left.svg" alt="" />
                </div>
                <div className="landing-more-information-text">
                </div>
                <div className="landing-more-information-img2">
                  <img src="img/landing/right.svg" alt="" />
                </div>
              </div>

              <div className="landing-footer">
                <div>
                  <div>
                    <img src="img/guip-style/footer-logotipo.svg" alt="" style={{ marginBottom: "10px" }} />
                  </div>
                  Güip - Sistema inteligente de captación de agua lluvia
              </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import axios from 'axios';

class assembleService {
    constructor() {
        this.gateway = process.env.REACT_APP_GATEWAY;
    }

    measures = (idType) => {
        return axios.get(this.gateway + '/assemble/api/assemble/measures/' + idType)
    }

    save = (jsonParams) => {
        return axios.post(this.gateway + '/assemble/api/assemble', jsonParams)
    }

    profile = (idProfile) => {
        return axios.get(this.gateway + '/assemble/api/assemble/profile/' + idProfile)
    }
}

const instance = new assembleService();

export default instance;
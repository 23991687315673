import React from 'react';


export default class Share extends React.Component {
    state = {
    };

    componentDidMount() {
    }

    go = () => {
        const phone = document.getElementById("phone").value;
        localStorage.setItem('phone', phone);
        window.location.replace("/#/share-logged-in");
    }

    render() {

        return (
            <div>
                <div className="page-header">
                    <img src="img/headers/header-heroes_agua.png" alt="" />
                </div>
                <div className="page-title">
                    Héroes del agua
                </div>

                <div className="page-text">
                    ¡Comparte tus logros y obtén recompensas!
                    <br />
                    Mira cómo lo han hecho otros.
                </div>
                <br />
                <div className="header-section-home">
                    <hr />
                    <span>
                        Novedades de la comunidad
                    </span>
                </div>
                <center>Aún no hay experiencias compartidas.</center>

                <br /><br />


                <div className="page-text">
                    ¡Comparte tus logros y obtén hasta un 30% de descuento!
                    <br />
                    Para hacerlo, introduce tu número de celular toca el botón de iniciar sesión.
                </div>

                <div className="input-box">
                    <span className="prefix">(+503)</span>
                    <input type="number" id="phone" placeholder="" />
                </div>

                <a href="#/share-logged-in">
                    <center>
                        <button className="accept-notifications button-form-notification" onClick={() => this.go()}>
                            Iniciar sesión
                        </button>
                    </center>
                </a>
            </div>
        );
    }
}
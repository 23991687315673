import React from 'react';

const whatsapp = () => {
    const number = process.env.REACT_APP_WHATSAPP;
    const message = "Contacto desde Guip";
    const url = 'https://api.whatsapp.com/send?phone='
        + number
        + '&text='
        + encodeURIComponent(message)
    window.open(url, "_blank")
}

const Contact = () => {

    return (
        <div>
            <div className="page-title-no-header-img">
                Contacto
            </div>

            <div className="page-text">
            Para más información sobre Guip te invitamos a escribirnos a nuestro correo electrónico, Whatsapp y seguirnos en nuestras redes sociales.
            <br/>
            <b>
            ¡Estamos para servirte!
            </b>
            </div>
            <br />
            <div className="page-text" style={{textAlign: "left"}}>
                Email: <span>{process.env.REACT_APP_EMAIL}</span>
            </div>

            <div className="page-text">
                Whatsapp: <span>{process.env.REACT_APP_WHATSAPP}</span>
            </div>

            <center>
                <button type="button" className="styled-button" onClick={() => whatsapp()}>
                    <div className="div-styled-button">
                        <img src="img/social/ws.png" alt="" />
                    </div>
                    <span className="text-styled-button">Solicitar ayuda</span>
                </button>
            </center>
        </div>
    );
}

export default Contact;

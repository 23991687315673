import React from 'react';
import notificationService from '../../../../services/notificationService'
import assembleService from '../../../../services/assembleService'

export default class AssembleProductive2 extends React.Component {
    state = {
        idType: null,
        name: null,
        departments: [],
        municipalities: [],
        measures: [],
        idMeasure: "",
        minSpace: null,
        styleSpace: "none"
    };

    componentDidMount() {
        const type = this.props.match.params.idType;
        this.setState({ idType: type })
        this.fetchMeasures(type)
        this.fetchDepartments();
    }

    fetchMeasures = (idType) => {
        assembleService.measures(idType)
            .then(data => {
                this.setState({
                    name: data.data.name,
                    measures: data.data.measures,
                    messageSelectOption: data.data.messageSelectOption
                })
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    fetchDepartments = () => {
        notificationService.departments()
            .then(data => this.setState({ departments: data.data }))
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    selectMucipalities = (event) => {
        const idDepartment = event.target.value;
        notificationService.municipalities(idDepartment)
            .then(data => this.setState({ municipalities: data.data }))
            .catch(err => {
                console.log(err);
                this.setState({ municipalities: [] })
                return null;
            });
    }

    check = (event) => {
        const idMeasure = event.target.value;
        const element = (this.state.measures.find(element => element.id === Number(idMeasure)));
        this.setState({
            idMeasure: idMeasure,
            minSpace: ((element) ? element.minSpace : null)
        })
    }

    showSpace = (event) => {
        const value = event.target.value;
        this.setState({
            styleSpace: (value === "no") ? "inline" : "none"
        })
    }

    buy = () => {
        const d = document.getElementById("departments");
        const department = d.options[d.selectedIndex].text;
        const m = document.getElementById("municipalities");
        const municipality = m.options[m.selectedIndex].text;
        const a = document.getElementById("answer");
        const answer = (a) ? a.options[a.selectedIndex].text : "";
        const c = document.getElementById("userCustomSpace");
        const userCustomSpace = (c) ? c.value : "";
        if (
            this.state.idType === null
            || this.state.idMeasure === ""
            || department === "Seleccione un departamento"
            || municipality === "Seleccione un municipio"
            || (a && answer === "Selecciona una opción")
            || (c && (answer === "No" && userCustomSpace === ""))
        ) {
            alert("Debes completar toda la información");
        } else {
            const jsonParams = {
                idType: this.state.idType,
                idMeasure: this.state.idMeasure,
                department: department,
                municipality: municipality,
                canton: document.getElementById("canton").value,
                hamlet: document.getElementById("hamlet").value,
                userCustomSpace: userCustomSpace
            }

            assembleService.save(jsonParams).then((data) => {
                window.location.replace("/#/productive3/" + data.data) //idProfile
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-header" style={{ marginTop: "-30px" }}>
                    <img src={"img/assemble/productive/header" + this.state.idType + ".jpg"} alt="" />
                    <a href="/#/productive1">
                        <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                    </a>
                </div>
                <br />
                <div className="page-title">
                    <b>
                        Calcula tu Inversión
                    </b>
                    <br />
                    Solución Productiva {this.state.name}
                </div>
                <div className="page-text">
                    Por último, completa la información del siguiente formulario.
                    <br />
                    <b>¡Te tomará un minuto!</b>
                </div>
                <br />
                <select name="medida" id="medida" className="calc-input" onChange={(event) => this.check(event)}>
                    <option value="">{this.state.messageSelectOption}</option>
                    {(this.state.measures.length > 0)
                        ?
                        (this.state.measures.map((element, index) => {
                            return (
                                <option value={element.id} key={index}>{element.value}</option>
                            )
                        }))
                        : ""
                    }
                </select>
                <div className="required">Campo obligatorio</div>

                <select name="departments" id="departments" className="calc-input" onChange={(event) => this.selectMucipalities(event)}>
                    <option value="">Seleccione un departamento</option>
                    {(this.state.departments.length > 0)
                        ?
                        (this.state.departments.map((element, index) => {
                            return (
                                <option value={element.id} key={index}>{element.value}</option>
                            )
                        }))
                        : ""
                    }
                </select>
                <div className="required">Campo obligatorio</div>

                <select name="municipalities" id="municipalities" className="calc-input" disabled={!(this.state.municipalities.length > 0)}>
                    <option value="">Seleccione un municipio</option>
                    {
                        (this.state.municipalities.map((element, index) => {
                            return (
                                <option value={element.id} key={index}>{element.value}</option>
                            )
                        }))
                    }
                </select>
                <div className="required">Campo obligatorio</div>

                <input type="text" className="calc-input" placeholder="Cantón (Campo no obligatorio)" id="canton" />
                <br />
                <input type="text" className="calc-input" placeholder="Caserío (Campo no obligatorio)" id="hamlet" />

                {this.state.minSpace &&
                    (<>
                        <b>El sistema necesita un espacio mínimo de {this.state.minSpace} ¿Tienes ese espacio?</b>
                        <select id="answer" className="calc-input" onChange={(event) => this.showSpace(event)}>
                            <option value="">Selecciona una opción</option>
                            <option value="si">Si</option>
                            <option value="no">No</option>
                        </select>
                        <div className="required">Campo obligatorio</div>

                        <input type="text" className="calc-input" placeholder="Cuéntanos ¿Con cuanto espacio cuentas?" id="userCustomSpace" style={{ display: this.state.styleSpace }} />
                        <div className="required" style={{ display: this.state.styleSpace }}>Campo obligatorio</div>
                    </>)
                }

                <button className="accept-notifications button-form-notification" onClick={() => this.buy()}>
                    ¡Calcula ya!
                </button>
            </div>
        );
    }
}

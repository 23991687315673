const gateway = process.env.REACT_APP_GATEWAY;
const notificationsUri = gateway + "/push-notifications";

function post(path, body) {
    console.log(JSON.stringify(body));
    return fetch(`${notificationsUri}${path}`, {
            credentials: "omit",
            headers: { "content-type": "application/json;charset=UTF-8" },
            body: JSON.stringify(body),
            method: "POST",
            mode: "cors"
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(data) {
            return data;
        });
}

function get(path) {
    return fetch(`${notificationsUri}${path}`, {
            credentials: "omit",
            headers: { "content-type": "application/json;charset=UTF-8" },
            method: "GET",
            mode: "cors"
        })
        .then(function(response) {
            return response.json();
        })
        .then(function(data) {
            return data;
        });
}

const http = {
    post: post,
    get: get
};

export default http;
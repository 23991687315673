import React from 'react';
import {
    Nav,
    Navbar,
    NavbarBrand,
    Collapse
} from 'reactstrap';
import logo from '../../../src/assets/images/guip-logo.svg';

const Header = (props) => {
    var hideHeader = false;
    var hideHeaderV2 = false;
    console.debug(props.location.pathname);
    if ((props.location.pathname.indexOf("selectVersion") > 0)) {
        hideHeader = true;
        hideHeaderV2 = true;
    } else if ((props.location.pathname.indexOf("v2") > 0)) {
        hideHeaderV2 = false;
        hideHeader = true;
    } else if (!(props.location.pathname.indexOf("share") > 0)) {
        const n = (parseInt(props.location.pathname.substr(props.location.pathname.length - 1, 1)))
        hideHeader = (!Number.isNaN(n));
        hideHeaderV2 = true
    } else {
        hideHeader = false
        hideHeaderV2 = true
    }

    const showMobilemenu = () => {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    return (
        <>
            {!hideHeader && (<header className="topbar navbarbg" data-navbarbg="skin1">
                <Navbar className="top-navbar" dark expand="md">
                    <div className="navbar-header" id="logobg" data-logobg="skin6">
                        <NavbarBrand href="#/home" style={{ padding: "0px", marginTop: "5px" }}>
                            <div className={(props.location.pathname === "/home") ? "logo" : "logo-left"}>
                                <img src={logo} alt="homepage" className="dark-logo" style={{ width: "24%" }} />
                                <div className="logo-name">
                                    Sistema inteligente de captación de agua lluvia
                                </div>
                            </div>
                        </NavbarBrand>
                        <button className="btn-link nav-toggler d-block d-md-none title-menu" onClick={() => showMobilemenu()}>
                            <span>Menú</span>
                            <i className="ti-menu ti-close" />
                            <div className="notification"></div>
                        </button>
                    </div>

                    <Collapse className="navbarbg" navbar data-navbarbg="skin1" >
                        <Nav className="ml-auto float-right" navbar>

                        </Nav>
                    </Collapse>
                </Navbar>
                {(props.location.pathname === "/home") &&
                    (<>
                        <img src="img/guip-style/d2.svg" className="header-detail2" alt="" />
                        <img src="img/guip-style/d1.svg" className="header-detail" alt="" />
                    </>)
                }
            </header>)}

            {!hideHeaderV2 && !(props.location.pathname.indexOf("silabarioV2Detail") > 0) && (<header className="topbar navbarbg" data-navbarbg="skin1" style={{ position: "fixed", width: "100%", zIndex: 999 }}>
                <Navbar className="top-navbar" dark expand="md">
                    {/* <div className="navbar-header" id="logobg" data-logobg="skin6">

                        <div style={{ width: "100%", position: "absolute", textAlign: "center" }}>
                            <center style={{ width: "100%" }}>
                                <a href="#/selectVersion">
                                    <img src={logo} alt="homepage" className="dark-logo logo-headerV2" style={{ width: "18%", marginTop: "-80px" }} />
                                </a>
                            </center>
                        </div>

                        <div className="parent">
                            <a href="#/v2/learn" style={{ color: "#3e5569" }}>
                                <div className={(props.location.pathname == "/v2/learn") ? "div1 gridActive" : "div1"}>
                                    <img src={(props.location.pathname == "/v2/learn") ? "img/headerV2/activados/comoLoCuido.svg" : "img/headerV2/desactivados/queEs.svg"} width="60%" alt="" style={{ width: "29%" }} />
                                    <br />
                                    ¿Qué es<br />Guip?
                                </div>
                            </a>
                            <a href="#/v2/alerts" style={{ color: "#3e5569" }}>
                                <div className={(props.location.pathname == "/v2/alerts") ? "div2 gridActive" : "div2"}>
                                    <img src={(props.location.pathname == "/v2/alerts") ? "img/headerV2/activados/queEs.svg" : "img/headerV2/desactivados/cuandoLlueve.svg"} width="60%" alt="" style={{ width: "40%" }} />
                                    <br />
                                    ¿Cuándo<br />llueve?
                                </div>
                            </a>
                            <a href="#/v2/price" style={{ color: "#3e5569" }}>
                                <div className={(props.location.pathname == "/v2/price") ? "div4 gridActive" : "div3"}>
                                    <img src={(props.location.pathname == "/v2/price") ? "img/headerV2/activados/cuantoCuesta.svg" : "img/headerV2/desactivados/cuantoCuesta.svg"} width="60%" alt="" style={{ width: "44%" }} />
                                    <br />
                                    ¿Cuánto<br />cuesta?
                                </div>
                            </a>
                            <a href="#/v2/maintenance" style={{ color: "#3e5569" }}>
                                <div className={(props.location.pathname == "/v2/maintenance") ? "div4 gridActive" : "div4"}>
                                    <img src={(props.location.pathname == "/v2/maintenance") ? "img/headerV2/activados/cuandoLlueve.svg" : "img/headerV2/desactivados/comoLoCuido.svg"} width="60%" alt="" style={{ width: "35%" }} />
                                    <br />
                                    ¿Cómo<br />lo cuido?
                                </div>
                            </a>
                            <a href="#/v2/heroes" style={{ color: "#3e5569" }}>
                                <div className={(props.location.pathname == "/v2/heroes") ? "div4 gridActive" : "div5"}>
                                    <img src={(props.location.pathname == "/v2/heroes") ? "img/headerV2/activados/heroes.svg" : "img/headerV2/desactivados/heroes.svg"} width="60%" alt="" style={{ width: "40%" }} />
                                    <br />
                                    Héroes<br />del agua
                                </div>
                            </a>
                        </div>
                    </div> */}


                    {(
                        <>
                            <div className="header-grid">
                                <div className="header-logo-basic">
                                    <a href="#/selectVersion">
                                        <img src={logo} alt="Inicio" className="dark-logo" style={{ width: "18%" }} />
                                        <br />
                                        <span style={{ fontSize: '11px' }}>
                                            Sistema inteligente de cosecha de agua lluvia
                                        </span>
                                    </a>
                                </div>
                                {/* Options */}
                                {(props.location.pathname !== "/v2/heroes-logged-in") ?
                                    (<>
                                        <a href="#/v2/learn" style={{ color: "#3e5569" }}>
                                            <div className={(props.location.pathname === "/v2/learn") ? "header-opt-1 header-opt-active" : "header-opt-1"}>
                                                <img src="img/headerV2/header-opt-1.svg" width="60%" alt="" style={{ width: "25%" }} />
                                                <br />
                                                ¿Qué es<br />Güip?
                                            </div>
                                        </a>
                                        <a href="#/v2/alerts" style={{ color: "#3e5569" }}>
                                            <div className={(props.location.pathname === "/v2/alerts") ? "header-opt-2 header-opt-active" : "header-opt-2"}>
                                                <img src="img/headerV2/header-opt-2.svg" width="60%" alt="" style={{ width: "35%" }} />
                                                <br />
                                                ¿Cuándo<br />llueve?
                                            </div>
                                        </a>
                                        <a href="#/v2/price" style={{ color: "#3e5569" }}>
                                            <div className={(props.location.pathname === "/v2/price") ? "header-opt-3 header-opt-active" : "header-opt-3"}>
                                                <img src="img/headerV2/header-opt-3.svg" width="60%" alt="" style={{ width: "42%" }} />
                                                <br />
                                                ¿Cuánto<br />cuesta?
                                            </div>
                                        </a>
                                        <a href="#/v2/maintenance" style={{ color: "#3e5569" }}>
                                            <div className={(props.location.pathname === "/v2/maintenance") ? "header-opt-4 header-opt-active" : "header-opt-4"}>
                                                <img src="img/headerV2/header-opt-4.svg" width="60%" alt="" style={{ width: "31%" }} />
                                                <br />
                                                ¿Cómo<br />lo cuido?
                                            </div>
                                        </a>
                                        <a href="#/v2/heroes" style={{ color: "#3e5569" }}>
                                            <div className={(props.location.pathname === "/v2/heroes") ? "header-opt-5 header-opt-active" : "header-opt-5"}>
                                                <img src="img/headerV2/header-opt-5.svg" width="60%" alt="" style={{ width: "29%" }} />
                                                <br />
                                                Héroes<br />del agua
                                            </div>
                                        </a>
                                    </>)
                                    : ""}
                            </div>
                        </>)}

                </Navbar>
            </header>)}
        </>
    );
}
export default Header;

import axios from 'axios';

class notificationService {
    constructor() {
        this.gateway = process.env.REACT_APP_GATEWAY;
    }

    departments = () => {
        return axios.get(this.gateway + '/notifications/api/departments')
    }

    checkSubscription = (idSubscription) => {
        return axios.get(this.gateway + '/notifications/api/subscription/' + idSubscription)
    }

    municipalities = (idDepartment) => {
        return axios.get(this.gateway + '/notifications/api/municipality/' + idDepartment)
    }

    subscribe = (subscription, endpoint, expirationTime, auth, p256dh, idMunicipality, isNotifiable) => {
        const jsonParams = {
            subscription: subscription,
            endpoint: endpoint,
            expirationTime: expirationTime,
            auth: auth,
            p256dh: p256dh,
            idMunicipality: idMunicipality,
            isNotifiable: isNotifiable
        }

        return axios.post(this.gateway + '/notifications/api/subscribe/', jsonParams)
    }

    detailList = (subscription) => {
        return axios.get(this.gateway + '/notifications/api/notifications/' + subscription)
    }

    getNotification = (idHis, idHisDet) => {
        return axios.get(this.gateway + '/notifications/api/notification/' + idHis + '/' + idHisDet)
    }

    getNotification = (idHis, idHisDet) => {
        return axios.get(this.gateway + '/notifications/api/notification/' + idHis + '/' + idHisDet)
    }

    getImpacts = (idHis, idHisDet) => {
        return axios.get(this.gateway + '/notifications/api/impacts/' + idHis + '/' + idHisDet)
    }

    sendValidation = (formData, config) => {
        return axios.post(this.gateway + '/notifications/api/save-validation', formData, config)
    }

    setPoints = (idMunicipality, userGoogleId, points) => {
        const jsonParams = {
            userGoogleId: userGoogleId,
            points: points,
            idMunicipality: idMunicipality
        }
        return axios.post(this.gateway + '/notifications/api/points/', jsonParams)
    }

    shareExperience = (phone, achievements, imgBase64, description) => {
        const jsonParams = {
            phone,
            achievements,
            imgBase64,
            description
        }
        return axios.post(this.gateway + '/notifications/api/experience/', jsonParams)
    }

    getExperiences = () => {
        return axios.get(this.gateway + '/notifications/api/experiences')
    }
}

const instance = new notificationService();

export default instance;
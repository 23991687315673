import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'

const Sidebar = (props) => {

    const navigation = [];
    const otherInformation = [];

    props.routes.forEach(element => {
        if (element.reel === true) {
            navigation.push(element);
        } else if (element.moreInformation === true) {
            otherInformation.push(element);
        }
    });

    const expandLogo = () => {
        document.getElementById("logobg").classList.toggle("expand-logo");
    }

    const activeRoute = (routeName) => {
        window.scrollTo(0, 0)
        return props.location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
    }

    const showMobilemenu = () => {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    return (
        <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6"  onMouseEnter={expandLogo.bind(null)} onMouseLeave={expandLogo.bind(null)}>
            <div className="scroll-sidebar">
                <PerfectScrollbar className="sidebar-nav">
                    <br />
                    <br />
                    <span className="menu-title">
                        Menú
                    </span>
                    <i className="close-sidebar mdi mdi-close" onClick={() => showMobilemenu()} />
                    <br />
                    <Nav id="sidebarnav">
                        {navigation.map((prop, key) => {
                            return (
                                <li  id={prop.path} className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                    <NavLink to={prop.path} className="sidebar-link">
                                        <img src={"img/sidebar/" + prop.img + ".svg"} alt={prop.path} />
                                        <span className="hide-menu">{prop.name}</span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </Nav>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <hr style={{ display: "inline-block", width: "80%" }} />
                    </div>

                    <span className="menu-secundary-text">
                        Más información
                    </span>
                    <br />
                    <Nav id="sidebarnav" style={{ background: "none" }}>
                        {otherInformation.map((prop, key) => {
                            return (
                                <li id={prop.path} className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                    <a href={"/#" + prop.path} className="sidebar-link">
                                        <img src={"img/sidebar/genericOpt.svg"} alt={prop.path} style={{ padding: "0px 25px 0px 20px" }} />
                                        <span className="hide-menu">{prop.name}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </Nav>
                    <img src={"img/guip-style/menu.svg"} alt="" style={{position: "absolute", width: "100%", zIndex: "-1" }} />
                </PerfectScrollbar>
            </div>
        </aside>
    );
}
export default Sidebar;

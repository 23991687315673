import React from 'react';
import logo from '../../../src/assets/images/guip-logo.svg';
import ReactPlayer from "react-player"
import { Modal, Button } from 'react-bootstrap'


export default class Landing extends React.Component {
    state = {
        show: false,
        title: "",
        text: ""
    };

    a = () => {
        this.setState({ title: "Inteligente" })
        this.setState({ text: "En Güip vinculamos personas e instituciones para fomentar un ecosistema de captación y aprovechamiento del agua lluvia, mediante una Plataforma Web App para brindar contenido de valor e interacciones de manera online a nuestros usuarios mediante sus dispositivos electrónicos." })
        this.handleShow();
    }


    b = () => {
        this.setState({ title: "Accesibilidad" })
        this.setState({ text: "Accesibilidad desde un enfoque de inclusión financiera para la población más vulnerable del país, con la cual se posibilite una solución integral de aprovechamiento del agua lluvia que mejore la vida de familias en zonas con deficiencias en la continuidad y cobertura del servicio de agua." })
        this.handleShow();
    }

    c = () => {
        this.setState({ title: "Centrado en las personas" })
        this.setState({ text: "Güip ha sido desarrollado desde metodologías participativas centradas en las personas, bajo un proceso participativo junto a usuarios de los dos segmentos a beneficiar con esta alternativa a su necesidad de acceso al servicio de agua." })
        this.handleShow();
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }

    componentDidMount() { console.log(localStorage.getItem("messagePC")); }

    render() {
        return (
            <>
                <div>
                    <div className="landing-header">
                    </div>
                    <div className="landing-content">
                        <div className="landing-ws">
                            <img src="img/landing/ws.svg" alt="" />
                            <span>&nbsp;&nbsp;Escríbenos: +503 6009-1866</span>
                        </div>

                        <div className="logo-video" >
                            <div className="logo-message">
                                <img src={logo} alt="homepage" className="dark-logo landing" />
                                <div className="logo-name landing">
                                    <b>
                                        Agua caída del cielo
                                    </b>
                                    <br />
                                    Sistema inteligente de captación de agua lluvia
                                <br />
                                    <div className="landing-mobile-message">
                                        <img src="img/landing/mobile.svg" alt="" />
                                        <div>
                                                    Disfruta de una mejor experiencia digital ingresando desde tu celular o tablet al Toolkit digital en formato Web App: <div> <b>https://guip.fonaes.gob.sv</b></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="landing-video">
                                <ReactPlayer className="video-guip" url="https://youtu.be/8Qd7-nvSs_k" />
                            </div>
                        </div>

                        <div className="landing-content-title">
                            <h2>
                                <b>
                                    Acerca de Güip
                                </b>
                            </h2>
                        </div>
                        <br />
                        <div className="landing-content-text">
                            <div>
                                <img src="img/landing/foto.jpg" alt="" />
                            </div>
                            <div className="landing-content-description">
                            Güip es un sistema inteligente para la captación y aprovechamiento del agua lluvia, surgió como una alternativa para personas y/o comunidades en zonas con deficiencias en la continuidad y cobertura del servicio de agua, beneficiando así a la población más vulnerable de El Salvador.
                            <br /><br />
                            Güip es una solución innovadora desarrollada aplicando metodologías ágiles con enfoque centrado en el usuario. La integralidad de Güip yace en sus cuatro componentes:
                            <ol style={{ paddingLeft: "14px" }}>
                                    <li>Prototipo del sistema de captura, conducción y almacenamiento de agua lluvia</li>
                                    <li>Plataforma digital WebApp</li>
                                    <li>Modelo de negocio</li>
                                    <li>Estrategia de comunicación</li>
                                </ol>

                            Güip busca mejorar vidas a través del uso inteligente de la abundante agua lluvia en El Salvador.
                            <br />
                                <br />

                                <b>
                                    <h4>¡Güip tu sistema de cosecha de agua lluvia!</h4>
                                </b>
                                Se parte de la solución.

                            </div>
                        </div>
                        {/* here */}
                        <div className="landing-bottom">
                            <div className="landing-sponsors">
                                <center>
                                    <div><h2>Características de la Solución</h2></div>
                                    <br />
                                    <div style={{ marginBottom: "22px" }}>A continuación te presentamos las características y el valor agregado que te ofrece Güip, en sus tipos de sistema de captación de agua lluvia domiciliar y productivo:</div>
                                    <div>
                                        <div className="learn-option" >
                                            <div className="elem">
                                                <div className="landing-option-name" id="centrado-personas">
                                                    <img src="img/landing/3.png" alt="" onClick={() => this.c()} />
                                                    <br />
                                                    <div className="car">
                                                        <b>
                                                            <center>
                                                                Centrado en las personas
                                                    </center>
                                                        </b>

                                                Una solución desarrollada desde metodologías participativas centradas en las personas a partir de la colaboración.
                                                </div>
                                                </div>
                                            </div>
                                            <div className="elem">
                                                <div className="landing-option-name" id="inteligente">
                                                    <img src="img/landing/1.png" alt="" onClick={() => this.a()} />
                                                    <br />
                                                    <div className="car">
                                                        <b>
                                                            <center>
                                                                Inteligente
                                                    </center>
                                                        </b>

                                                Vinculamos personas e instituciones para fomentar un ecosistema de aprovechamiento de agua lluvia a partir de tecnologías de información y comunicación e innovación digital.
                                                </div>
                                                </div>
                                            </div>
                                            <div className="elem">

                                                <div className="landing-option-name" id="accesibilidad">
                                                    <img src="img/landing/2.png" alt="" onClick={() => this.b()} />
                                                    <br />
                                                    <div className="car">
                                                        <b>
                                                            <center>
                                                                Accesibilidad
                                                    </center>
                                                        </b>

                                                Acercamos desde un enfoque de accesibilidad e inclusión financiera una solución integral para el aprovechamiento del agua lluvia, con el objetivo de mejorar la vida de las personas.
                                                </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </center>
                            </div>

                            <br />
                            <br />
                            <br />

                            <div className="landing-more-information">
                                <div className="landing-more-information-img1">
                                    <img src="img/landing/left.svg" alt="" />
                                </div>
                                <div className="landing-more-information-text">
                                    <center>
                                        Para más información sobre Güip siguenos en nuestras redes sociales.
                                    <br />
                                        <b>
                                            ¡Estamos para servirte!
                                    </b>
                                        <br />
                                        <div className="landing-more-information-icons">
                                            <a href="https://www.facebook.com/GuipSv/" target="_blank" rel="noopener noreferrer" id="fb">
                                                <img src="img/landing/fb.svg" alt="" />
                                            </a>
                                            <a href="https://twitter.com/Guip_sv" target="_blank" rel="noopener noreferrer" id="tw">
                                                <img src="img/landing/tw.svg" alt="" />
                                            </a>
                                            <a href="https://www.youtube.com/channel/UCLeAenn1MGP15HADgnwvB3A/featured" target="_blank" rel="noopener noreferrer" id="yt">
                                                <img src="img/landing/youtube.png" alt="" />
                                            </a>
                                        </div>
                                    </center>
                                </div>
                                <div className="landing-more-information-img2">
                                    <img src="img/landing/right.svg" alt="" />
                                </div>
                            </div>

                            <div className="landing-footer">
                                <div>
                                    <div>
                                        <img src="img/guip-style/footer-logotipo.svg" alt="" style={{ marginBottom: "10px" }} />
                                    </div>
                                Güip - Sistema inteligente de captación de agua lluvia
                                </div>
                            </div>
                        </div>
                        {/* here */}
                    </div>
                </div>



                <Modal
                    show={this.state.show}
                    onHide={() => this.handleClose()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.text}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleClose()}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const pushServerPublicKey = "BIN2Jc5Vmkmy-S3AUrcMlpKxJpLeVRAfu9WBqUbJ70SJOCWGCGXKY-Xzyh7HDr6KbRDGYHjqZ06OcS3BjD7uAm8";

function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
    return await Notification.requestPermission();
}

function registerServiceWorker() {
    return navigator.serviceWorker.register("/sw.js");
}

async function createNotificationSubscription() {
    //wait for service worker installation to be ready
    const serviceWorker = await navigator.serviceWorker.ready;
    // subscribe and return the subscription
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey
    });

}

function getUserSubscription() {
    //wait for service worker installation to be ready, and then
    return navigator.serviceWorker.ready
        .then(function(serviceWorker) {
            return serviceWorker.pushManager.getSubscription();
        })
        .then(function(pushSubscription) {
            return pushSubscription;
        });
}

export {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription
};
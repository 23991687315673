import React from 'react';
import Cookies from 'universal-cookie';
import notificationService from '../../../services/notificationService'
import {
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription
} from "../../../pushNotifications/push-notifications";
import http from "../../../util/http";
import ReactPlayer from "react-player"
import LoadingAnimation from '../../../components/animations/loadingAnimation'
import ClimaAnimation from '../../../components/animations/climaAnimation'


export default class Notifications extends React.Component {
    state = {
        isNotifiable: false,
        subscription: null,
        loading: false,
        ga: null,
        departments: [],
        municipalities: [],
        municipality: null,
        todayNotifications: [],
        lastNotifications: [],
        rain: null
    };

    isPushNotificationSupported = () => {
        return "serviceWorker" in navigator && "PushManager" in window;
    }

    getNotificationsSubscription = async () => {
        const subscription = await getUserSubscription();
        http.post("/subscription", subscription)
            .then((response) => {
                this.setState({ subscription: response.id })
                this.notificationsDetail(this.state.subscription)
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchDepartments = () => {
        notificationService.departments()
            .then(data => this.setState({ departments: data.data }))
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    componentDidMount() {
        const cookies = new Cookies();
        this.setState({ isNotifiable: this.isPushNotificationSupported() });
        if (!this.isPushNotificationSupported()) {
            //check id ga exists in subscriptions
            notificationService.checkSubscription(cookies.get('_ga'))
                .then(data => {
                    if (data.data) {
                        this.setState({ ga: cookies.get('_ga') });
                        this.notificationsDetail(this.state.ga);
                    } else {
                        this.fetchDepartments();
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ list: [] })
                    return null;
                });
        } else {
            const type = Notification.permission;
            if (type === "granted") {
                this.getNotificationsSubscription();
            } else {
                this.fetchDepartments();
                console.debug("Cuando acepte se le crea el subscription");
            }
        }
    }

    selectMucipalities = (event) => {
        const idDepartment = event.target.value;
        notificationService.municipalities(idDepartment)
            .then(data => this.setState({ municipalities: data.data }))
            .catch(err => {
                console.log(err);
                this.setState({ municipalities: [] })
                return null;
            });
    }

    setMunicipality = (event) => {
        this.setState({ municipality: event.target.value })
    }

    onClickAskUserPermission = () => {
        const cookies = new Cookies();
        const d = document.getElementById("departments");
        const department = d.options[d.selectedIndex].text;
        const m = document.getElementById("municipalities");
        const municipality = m.options[m.selectedIndex].text;

        if (department === "Seleccione un departamento"
            || municipality === "Seleccione un municipio") {
            alert("Debes seleccionar un municipio");
        } else {
            if (this.state.isNotifiable) {
                askUserPermission().then(consent => {
                    if (consent === "granted") {
                        registerServiceWorker();

                        this.setState({
                            loading: true
                        })

                        createNotificationSubscription()
                            .then((subscrition) => {
                                http.post("/subscription", subscrition)
                                    .then((response) => {
                                        console.log(response);
                                        this.setState({
                                            subscription: response.id
                                        })
                                        const subscription = this.state.subscription;
                                        const endpoint = response.body.endpoint;
                                        const expirationTime = response.body.expirationTime;
                                        const auth = response.body.keys.auth;
                                        const p256dh = response.body.keys.p256dh;
                                        const idMunicipality = this.state.municipality;
                                        notificationService.subscribe(subscription, endpoint, expirationTime, auth, p256dh, idMunicipality, 1).then((data) => {
                                            this.notificationsDetail(subscription)
                                        });
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false
                                        })
                                        console.log(err);
                                    });
                            })
                            .catch(err => {
                                console.log("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                            });
                    } else {
                        alert("Para recibir las notificaciones del MARN debes aceptarlas.")
                    }
                });
            } else {
                const idMunicipality = this.state.municipality;
                notificationService.subscribe(cookies.get('_ga'), idMunicipality, 0).then((data) => {
                    this.notificationsDetail(cookies.get('_ga'))
                });
            }
        }
    }

    notificationsDetail = (subscription) => {
        this.setState({
            loading: true
        })
        notificationService.detailList(subscription)
            .then(data => {
                this.setState(
                    {
                        municipalityName: data.data.municipality,
                        todayNotifications: data.data.todayNotifications,
                        lastNotifications: data.data.lastNotifications,
                        rain: data.data.rain,
                        loading: false
                    })
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    todayNotifications: [],
                    lastNotifications: [],
                    loading: false
                })
                this.notificationsDetail(subscription);
                return null;
            });
    }

    render() {
        return (
            <div>
                <div className="page-header">
                    <img src="img/headers/header-notificaciones.png" alt="" />
                </div>
                <div className="page-title">
                    Alertas del clima
                </div>
                {!(this.state.subscription !== null || this.state.ga !== null)
                    ?
                    !(this.state.loading)
                        ?
                        (<>
                            <div className="page-text">Recibe notificaciones sobre:</div>
                            <center>
                                <ClimaAnimation height={200} width={200} />
                            </center>
                            <br />
                            <div className="page-text">
                                Llena el siguiente formulario para recibir las notificaciones climáticas y estas aparecerán automáticamente en la página de inicio una vez lo hayas completado.
                                <br />
                                La información es proporcionada por el Centro de Monitoreo de Amenazas Naturales del Observatorio Ambiental de El Salvador.
                            </div>
                            <br />
                            <br />
                            <div className="page-text">
                                También puedes ver el siguiente video para mayor información:
                                <br />
                                <div className="article-box">
                                    <div className="article-box-img">
                                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=FRR5GIpNbQE" />
                                    </div>
                                    <div className="article-box-content">
                                        <div className="article-box-text">Pronóstico basado en impactos</div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="page-text">
                                Para recibir información del clima  introduce el departamento y municipio en el que vives.
                            </div>
                            <br />
                            <div className="page-text">
                                Departamento:
                                <select name="department" id="departments" className="calc-input" onChange={(event) => this.selectMucipalities(event)}>
                                    <option value="">Seleccione un departamento</option>
                                    {(this.state.departments.length > 0)
                                        ?
                                        (this.state.departments.map((element, index) => {
                                            return (
                                                <option value={element.id} key={index}>{element.value}</option>
                                            )
                                        }))
                                        : ""
                                    }
                                </select>
                                <div className="required">Campo obligatorio</div>

                                Municipio:
                                <select name="municipalities" id="municipalities" className="calc-input" disabled={!(this.state.municipalities.length > 0)}
                                    onChange={(event) => this.setMunicipality(event)}>
                                    <option value="">Seleccione un municipio</option>
                                    {
                                        (this.state.municipalities.map((element, index) => {
                                            return (
                                                <option value={element.id} key={index}>{element.value}</option>
                                            )
                                        }))
                                    }
                                </select>
                                <div className="required">Campo obligatorio</div>
                            </div>

                            <div className="page-text">
                                Cuando vuelvas a entrar a esta página verás la información climática de tu lugar de residencia.
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <button disabled={this.state.municipalities.length === 0} onClick={() => this.onClickAskUserPermission()} className="accept-notifications">
                                    Recibir notificaciones
                                </button>
                            </div>
                            

                        </>)
                        :
                        <LoadingAnimation height={100} width={100} />
                    :
                    !(this.state.loading) ?
                        (<>
                            <div className="page-text">
                                Estas son la notificaciones en el municipio de: <b>{this.state.municipalityName}.</b>
                                <br />
                                {(this.state.rain) ? "En este municipio existe probabilidad de " + this.state.rain + "." : ""}
                            </div>

                            <div className="notification-content-title">Notificaciones nuevas</div>
                            {

                                (this.state.todayNotifications.length > 0)
                                    ?
                                    (this.state.todayNotifications.map((element, index) => {
                                        return (
                                            <a href={"#/notificationDetail/" + element.idHis + "/" + element.idHisDet} key={index}>
                                                <div className="notification-box" key={index}>
                                                    <div className="notification-box-img">
                                                        <img src={"img/notifications/notification0" + element.idArea + ".png"} alt="" />
                                                    </div>
                                                    <div className="notification-box-content">
                                                        <div className="notification-box-type"
                                                            style={{ backgroundColor: element.notificationBackground, color: element.notificationFontColor }}
                                                        >{element.type}</div>
                                                        <div className="notification-box-time">{element.date}</div>
                                                        <div className="notification-box-text">
                                                            {(element.title[0].length > 100) ? element.title[0].substr(0, 100) + "..." : element.title[0]}
                                                        </div>
                                                        <div className="notification-box-detail">
                                                            Ver detalle
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    }))
                                    : <center>No hay notificaciones aún por parte del MARN</center>
                            }

                            <div className="notification-content-title">Notificaciones anteriores</div>
                            {

                                (this.state.lastNotifications.length > 0)
                                    ?
                                    (this.state.lastNotifications.map((element, index) => {
                                        return (
                                            <a href={"#/notificationDetail/" + element.idHis + "/" + element.idHisDet} key={index}>
                                                <div className="notification-box" key={index}>
                                                    <div className="notification-box-img">
                                                        <img src={"img/notifications/notification0" + element.idArea + ".png"} alt="" />
                                                    </div>
                                                    <div className="notification-box-content">
                                                        <div className="notification-box-type"
                                                            style={{ backgroundColor: element.notificationBackground, color: element.notificationFontColor }}
                                                        >{element.type}</div>
                                                        <div className="notification-box-time">{element.date}</div>
                                                        <div className="notification-box-text">
                                                            {(element.title[0].length > 100) ? element.title[0].substr(0, 100) + "..." : element.title[0]}
                                                        </div>
                                                        <div className="notification-box-detail">
                                                            Ver detalle
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    }))
                                    : <center>No hay notificaciones aún por parte del MARN</center>
                            }
                        </>)
                        : <LoadingAnimation height={100} width={100} />
                }
            </div >
        );
    }
}



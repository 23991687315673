import React from 'react';

const SelectGuip = () => {

    return (
        <div>
            <div className="page-header">
                <img src="img/headers/header-calculadora.png" alt="" />
            </div>
            <div className="page-title">
                Soluciones y precios
            </div>
            <div className="page-text">
                En Güip queremos ayudarte a identificar la mejor solución para tu hogar o negocio, por lo cual aquí podrás obtener un estimado del costo y la forma en la que puedes adquirir el sistema.
                <br />
                <br />
                Para comenzar, toca la imagen del lugar en donde te gustaría utilizar Güip:
            </div>
            <br />
            <center>
                <a href="/#/individual1">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/individual/urbana.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Domiciliar
                            </div>
                        </div>
                    </div>
                </a>

                <a href="/#/productive1">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/productive/agricola.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Productivo
                            </div>
                        </div>
                    </div>
                </a>

            </center>
        </div>
    );
}

export default SelectGuip;

import React from 'react';
import ReactDOM from 'react-dom';
import indexRoutes from './routes/index.jsx';
import {  Route, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom'
import './assets/scss/style.css';
//import GoogleTagManager from './components/GoogleTagManager'
//import { hotjar } from 'react-hotjar';

//hotjar.initialize(2058668, 6)
ReactDOM.render(
  <>
  <HashRouter>
    <Switch >
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} key={key} component={prop.component}  />;
      })}
    </Switch>
  </HashRouter>
  {/* <GoogleTagManager gtmId='GTM-KPGX8Z4' /> */}
  </>
  ,document.getElementById('root')); 

import React from 'react';

export default class NotificationValidate extends React.Component {
    state = {
        listImpacts: [],
        selectedImpact: null
    };
    componentDidMount() {

    }

    obtain = () => {
        window.location.replace("/#/share/"+this.props.match.params.idMunicipality);
    }

    render() {
        return (
            <div>
                <div className="page-header" style={{ marginTop: "-30px" }}>
                    <a href="/#/notifications">
                        <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                    </a>
                </div>
                <br /><br /><br />
                <div className="page-title-notification">
                    ¡Gracias por reportar!
                </div>
                <br />
                <div className="page-text">
                    El MARN ya recibio el reporte.
                    <br />
                    Gracias a tu colaboración las notificaciones son más precisas.
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                    <button className="accept-notifications button-form-notification" onClick={() => this.obtain()}>
                        Recibir 5 gotas en mi perfil
                    </button>
                    <b>Pare recibir las gotas necesitaras iniciar sesión con Google en la siguiente pantalla.</b>
                </div>
            </div >
        );
    }
}



import React from "react";
import Lottie from "react-lottie";
import soundLeft from "./json/sound-left.json";
import soundRight from "./json/sound-right.json";

function TurnUpVolumne(props) {
  const value = Math.floor(Math.random() * 2);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (value === 0) ? soundLeft : soundRight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={props.height} width={props.width} />;
}

export default TurnUpVolumne;
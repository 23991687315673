import React from 'react';

const About = () => {

    return (
        <div>
            <div className="page-title-no-header-img">
                Sobre el proyecto
            </div>

            <div style={{ margin: "0px 0px 20px -30px", width: "120%" }}>
                <img src="img/others/about.png" alt="" style={{ width: "100%" }} />
            </div>

            <div className="page-text">
            Guip es una iniciativa promovida por el Banco Interamericano de Desarrollo que nace a partir de la necesidad de acceso a agua potable de más de 600,000 salvadoreños y la intermitencia en el servicio de aproximadamente el 50% de las personas que sí cuentan con dicho recurso. Para solucionar ese gran desafío, se ha diseñado a partir de un proceso de investigación y desarrollo, un sistema de colecta y cosecha de agua lluvia para acercar a la población una innovación social apropiada y de bajo costo, que posibilite hacer accesible a las personas de la base de la pirámide salvadoreña, una alternativa para almacenar y utilizar este vital recurso durante la época lluviosa, que dura aproximadamente 6 meses del año.
            <br/><br/>
            Las soluciones han sido desarrolladas desde el paradigma de diseño centrado en las personas, bajo un proceso iterativo desde la co-creación junto a usuarios tempranos de los dos segmentos a impactar, así como también de la mano de actores clave para el éxito de esta iniciativa; todo ello desde un enfoque multi-actor que involucra instituciones públicas, privadas, financieras y organizaciones de la sociedad civil para trabajar juntos desde un sólo propósito que es, viabilizar el cumplimiento del derecho universal  de acceso al agua.
            <br/><br/>
            Esto reducirá los costos de adquisición del vital líquido en las familias de asentamientos urbanos o rurales de alta vulnerabilidad y de micro y pequeños productores del sector agropecuario;  por tanto ayudará a  mejorar la calidad de vida de las personas.
            <br/><br/>
            <b>¡Guip tu sistema de cosecha de agua lluvia! Se parte de la solución.</b>
            </div>

            <div className="home-footer">
                <div className="home-footer-img">
                    <img src="img/footer.svg" alt="" />
                </div>
            </div>
        </div>
    );
}

export default About;

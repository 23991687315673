import React from 'react';
import notificationService from '../../../services/notificationService'
import { getUserSubscription } from "../../../pushNotifications/push-notifications";
import http from "../../../util/http";
import Cookies from 'universal-cookie';

export default class NotificationDeatail extends React.Component {
    state = {
        idMunicipality: null,
        municipalityName: null,
        detail: [],
        title: [],
        type: null,
        notificationBackground: null,
        notificationFontColor: null,
        date: null,
        validate: false,
        subscription: null,
        ga: null
    };

    componentDidMount() {
        if (this.isPushNotificationSupported()) {
            this.getNotificationsSubscription();
        } else {
            this.getGA();
        }
        const idHis = this.props.match.params.idHis;
        const idHisDet = this.props.match.params.idHisDet;

        notificationService.getNotification(idHis, idHisDet)
            .then(data => {
                this.setState(
                    {
                        municipalityName: data.data.municipality,
                        idMunicipality: data.data.idMunicipality,
                        detail: data.data.detail,
                        title: data.data.title,
                        type: data.data.type,
                        notificationBackground: data.data.notificationBackground,
                        notificationFontColor: data.data.notificationFontColor,
                        date: data.data.date,
                        validate: data.data.validate
                    })
            })
            .catch(err => {
                console.log(err);
                this.setState({ detail: null })
                return null;
            });
    }

    isPushNotificationSupported = () => {
        return "serviceWorker" in navigator && "PushManager" in window;
    }

    getNotificationsSubscription = async () => {
        const subscription = await getUserSubscription();
        http.post("/subscription", subscription)
            .then((response) => {
                this.setState({ subscription: response.id })
            })
            .catch(err => {
                console.log(err);
            });
    }

    getGA = () => {
        const cookies = new Cookies();
        notificationService.checkSubscription(cookies.get('_ga'))
            .then(data => {
                if (data.data) {
                    this.setState({ ga: cookies.get('_ga') });
                }
            })
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    ws = () => {
        const href = window.location.href;
        navigator.clipboard.writeText(href);
        alert("El link de la notificación ha sido copiado, ahora puedes pegarlo en WhatsApp");
    }

    render() {
        return (
            <div>
                <div className="page-header" style={{ marginTop: "-30px" }}>
                    <img src="img/notifications/notification_area01.png" alt="" />
                    <a href="/#/notifications">
                        <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                    </a>
                </div>
                <div className="page-text">
                    Notificacion para el municipio: <b>{this.state.municipalityName}</b>
                </div>
                <br />
                <div className="notification-box-type"
                    style={{ backgroundColor: this.state.notificationBackground, color: this.state.notificationFontColor, fontWeight: "bolder" }}
                >{this.state.type}</div>
                <div className="notification-box-time">{this.state.date}</div>

                <div className="page-title-notification">
                    <ul>
                        {this.state.title.map((item, index) => {
                            return (
                                <li key={index} style={{fontSize: "20px"}}>{item}</li>
                            )
                        })}
                    </ul>
                </div>
                <br/>
                <div style={{color: this.state.notificationBackground, background: "#2e3740", borderRadius: "10px", margin:"-10px", padding: "10px 15px 10px 10px"}}>

                    <div className="page-text">
                        <b>Más información del MARN</b>
                    </div>

                    <div className="page-text">
                        <ul>
                            {this.state.detail.map((item, index) => {
                                return (
                                    <li key={index} style={{fontWeight: "lighter"}}>{item}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <br/>
                <center>
                    <button type="button" className="styled-button" onClick={() => this.ws()}>
                        <div className="div-styled-button">
                            <img src="img/social/ws.png" alt="" />
                        </div>
                        <span className="text-styled-button">Link para compartir</span>
                    </button>
                    Luego de dar clic al botón, el link estará copiado para que puedas dirigirte a Whatsapp y compartirlo.
                </center>
                {(this.state.subscription || this.state.ga) && this.state.validate && (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <a href={"/#/notification-validate/" + this.props.match.params.idHis + "/" + this.props.match.params.idHisDet + "/" + this.state.idMunicipality}>
                                <button className="accept-notifications">
                                    Ayudanos a validar esta notificación
                                </button>
                            </a>
                        </div>
                        <span>
                            Para ayudarnos a validar la información deberás habilitar la localización del celular cuando se te solicite y tomar una foto para luego agregarla
                        </span>
                    </>)
                }
            </div >
        );
    }
}



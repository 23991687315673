import React from 'react';
import SoundAnimation from '../../components/animations/soundAnimation'
import ReactPlayer from "react-player"

export default class SelectVersion extends React.Component {

    state = {
        voice: null,
        synth: null
    };

    whatsapp = () => {
        const number = process.env.REACT_APP_WHATSAPP;
        const message = "Contacto desde GUIP";
        const url = 'https://api.whatsapp.com/send?phone='
            + number
            + '&text='
            + encodeURIComponent(message)
        window.open(url, "_blank")
    }

    componentDidMount() {
        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
    }

    render() {
        return (
            <div>
                <br /><br /><br />
                <br /><br /><br />
                <br />
                <center>
                    <div onClick={() => this.speak("Descubre los tipos de solución que Güip te ofrece y conoce sus precios. Para mirar esta información, desliza tu dedo de arriba a abajo por la pantalla.")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <br />

                <div className="page-title">
                    ¿Cuánto cuesta?
                </div>

                <div className="page-text">
                    ¡Descubre los distintos tipos de solución que Güip te ofrece!
                </div>
                <br />
                <center>
                    <div onClick={() => this.speak("¡Descubre los distintos tipos de solución que Güip te ofrece!")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                    <br />
                </center>

                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://youtu.be/KTyxKMyKmvY" />
                    </div>
                    <div className="article-box-content">
                        {/* <div className="article-box-text">Pasos</div> */}
                        <div className="article-box-text">Demostración</div>
                        <div className="article-box-detail tutorial">Duración: 30 segundos <br /> Dificultad: Fácil</div>
                    </div>
                </div>
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=HjFiDPxL778" />
                    </div>
                    <div className="article-box-content">
                        {/* <div className="article-box-text">Pasos</div> */}
                        <div className="article-box-text">Demostración</div>
                        <div className="article-box-detail tutorial">Duración: 30 segundos <br /> Dificultad: Fácil</div>
                    </div>
                </div>

                <br />
 
                <center>
                    <div onClick={() => this.speak("Si quieres conocer los precios más específicos, contáctanos dando click en el botón de WhatsApp")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>
                <div className="page-text">
                    Si quieres conocer los precios de alguno de los tipos de soluciones ¡Contáctanos!
                </div>

                <center>
                    <button type="button" className="styled-button" onClick={() => this.whatsapp()}>
                        <div className="div-styled-button">
                            <img src="img/social/ws.png" alt="" />
                        </div>
                        <span className="text-styled-button">Solicitar ayuda</span>
                    </button>
                </center>

            </div>
        );
    }
}
import React from 'react';
import assembleService from '../../../../services/assembleService'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class AssembleIndividual3 extends React.Component {
    state = {
        buy: false,
        model: null,
        amount: null,
        capacity: null,
        idModel: null,
        idType: null
    };

    componentDidMount() {
        this.fetchProfile(this.props.match.params.idProfile);
    }

    fetchProfile = (idProfile) => {
        assembleService.profile(idProfile)
            .then(data => this.setState(
                {
                    buy: data.data.buy,
                    model: data.data.measure.model,
                    amount: data.data.measure.amount,
                    capacity: data.data.measure.capacity,
                    idModel: data.data.measure.id,
                    idType: data.data.measure.idType
                }))
            .catch(err => {
                console.log(err);
                return null;
            });
    }

    whatsapp = () => {
        const number = process.env.REACT_APP_WHATSAPP;
        const message = "Contacto desde GUIP";
        const url = 'https://api.whatsapp.com/send?phone='
            + number
            + '&text='
            + encodeURIComponent(message)
        window.open(url, "_blank")
    }

    render() {
        return (
            (this.state.buy) ? (
                <div>
                    <div className="page-header" style={{ marginTop: "-30px" }}>
                        <Carousel width={"100%"} infiniteLoop={true} showThumbs={false} showStatus={false}>
                            <div>
                                <img src={"img/assemble/individual/renders/render-T" + this.state.idType + "-1.jpg"} alt="" />
                                <p className="legend">Imagen de referencia 1</p>
                            </div>
                            <div>
                                <img src={"img/assemble/individual/renders/render-T" + this.state.idType + "-2.jpg"} alt="" />
                                <p className="legend">Imagen de referencia 2</p>
                            </div>
                            <div>
                                <img src={"img/assemble/individual/renders/render-T" + this.state.idType + "-3.jpg"} alt="" />
                                <p className="legend">Imagen de referencia 3</p>
                            </div>
                        </Carousel>
                    </div>

                    <center>
                        <button type="button" className="styled-button-tutorial link-shape-diver">
                            <span className="text-styled-button-tutorial">
                                <a href="https://app.shapediver.com/m/solucion-domiciliar-rural" target="_blank" rel="noopener noreferrer">
                                Calcula en la Maqueta 3d
                        </a>
                            </span>
                        </button>
                    </center>

                    {/* <div className="calc-steps" style={{ fontWeight: "bold" }}>Sistema</div> */}
                    <div className="news-title">
                        {this.state.model + " (Código GUIP-" + this.state.idModel + ")"}
                    </div>

                    <div className="calc-steps" style={{ fontWeight: "bold" }}>Precio del sistema</div>
                    <div className="news-title">
                        ${this.state.amount}.00
                    </div>

                    <div className="calc-steps" style={{ fontWeight: "bold" }}>Capacidad de agua recolectada</div>
                    <div className="news-title">
                        {this.state.capacity} litros
                    </div>

                    <hr />

                    <div className="calc-steps" style={{ fontSize: "16px" }}>
                        {/* Compra el sistema en <b>FREUND</b> y p */}Presenta este código para que tengas un descuentos de $25.00
            </div>

                    <div style={{ background: "#CAF2FD", border: "1px solid #000", borderRadius: "10px", borderStyle: "dashed", textAlign: "center", fontSize: "20px", padding: "10px" }}>
                        Código de descuento
                <br />
                GUIP25
            </div>
                    <div className="calc-steps" style={{ color: "red", fontSize: "16px", textAlign: "center" }}>
                        vence en 7 días
            </div>

{/*                     <center>
                        <button type="button" className="styled-button-tutorial link-shape-diver">
                            <span className="text-styled-button-tutorial">
                                <a href="https://www.freundferreteria.com/Tiendas" target="_blank" rel="noopener noreferrer">
                                    Ver información de sucursales
                        </a>
                            </span>
                        </button>
                    </center> */}

                    <center>
                        <button type="button" className="styled-button-tutorial link-shape-diver">
                            <span className="text-styled-button-tutorial">
                                <a href="/#/">
                                    Regresar al inicio
                        </a>
                            </span>
                        </button>
                    </center>

                </div>)
                : (
                    <div>
                        <div className="page-header" style={{ marginTop: "-30px" }}>
                            <img src="img/articles/img10.jpg" alt="" />
                            <a href="/#/select-guip">
                                <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                            </a>
                        </div>
                        <div className="page-title-notification">
                            Contáctanos para brindarte una asesoría más detallada.
                        </div>
                        <br />
                        <div className="page-text">
                        Actualmente no contamos con un tipo de solución que cubra tu necesidad de agua o el espacio con el que cuentas es muy pequeño.
                        <br/>
                        <b>
                        Te Invitamos a contactarnos a través de los siguientes medios de comunicación:
                        </b>
                        </div>
                        <br />
                        <div className="page-text" style={{textAlign: "left"}}>
                            Email: <span>{process.env.REACT_APP_EMAIL}</span>
                        </div>

                        <div className="page-text">
                            Whatsapp: <span>{process.env.REACT_APP_WHATSAPP}</span>
                        </div>

                        <center>
                            <button type="button" className="styled-button" onClick={() => this.whatsapp()}>
                                <div className="div-styled-button">
                                    <img src="img/social/ws.png" alt="" />
                                </div>
                                <span className="text-styled-button">Solicitar ayuda</span>
                            </button>
                        </center>

                        <center>
                            <button type="button" className="styled-button-tutorial link-shape-diver">
                                <span className="text-styled-button-tutorial">
                                    <a href="/#/">
                                        Regresar al inicio
                                    </a>
                                </span>
                            </button>
                        </center>
                    </div >

                )
        );
    }

}
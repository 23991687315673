import Home from '../views/options/home/Home.jsx';
import HomeDetail from '../views/options/home/HomeDetail.jsx';
import Learn from '../views/options/Learn.jsx';
import MainTutorial from '../views/options/tutorial/mainTutorial.jsx';
import TutorialCommunity from '../views/options/tutorial/Community.jsx';
import TutorialIndividual from '../views/options/tutorial/Individual.jsx';
import TutorialProductive from '../views/options/tutorial/Productive.jsx';
import SelectGuip from '../views/options/select-guip/SelectGuip.jsx';
import Individual1 from '../views/options/select-guip/individual/Individual1.jsx';
import Individual2 from '../views/options/select-guip/individual/Individual2.jsx';
import Individual3 from '../views/options/select-guip/individual/Individual3.jsx';
import Productive1 from '../views/options/select-guip/productive/Productive1.jsx';
import Productive2 from '../views/options/select-guip/productive/Productive2.jsx';
import Productive3 from '../views/options/select-guip/productive/Productive3.jsx';
import Notifications from '../views/options/notifications/Notifications.jsx';
import NotificationDetail from '../views/options/notifications/NotificationDetail.jsx';
import NotificationValidate from '../views/options/notifications/NotificationValidate.jsx';
import NotificationValidateSent from '../views/options/notifications/NotificationValidateSent.jsx';
import Share from '../views/options/Share.jsx';
import ShareLoggedIn from '../views/options/Share-logged-in.jsx';
//import Sponsors from '../views/options/Sponsors.jsx';
import About from '../views/options/About.jsx';
import Contact from '../views/options/Contact.jsx';
import Landing from '../views/options/Landing.jsx';
import Package from '../views/unboxing/Package'
import Main from '../views/unboxing/Main'
import Unboxing from '../views/unboxing/Unboxing'
import Assemble from '../views/unboxing/Assemble'
//

import SelectVersion from '../views/selectVersion/selectVersion.jsx';
import SilabarioV2 from '../views/v2/silabarioV2.jsx';
import SilabarioV2Detail from '../views/v2/silabarioV2Detail';
import AlertsV2 from '../views/v2/alertsV2.jsx';
import PriceV2 from '../views/v2/priceV2.jsx';
import MaintenanceV2 from '../views/v2/maintenanceV2.jsx';
import HeroesV2 from '../views/v2/heroesV2.jsx';
import HeroesV2LoggedIn from '../views/v2/heroesV2-logged-in';


var ThemeRoutes = [

  {
    path: '/home',
    name: 'Guip',
    component: Home
  },
  {
    path: '/learn',
    name: 'Silabario del agua',
    img: 'opt01',
    reel: true,
    moreInformation: false,
    component: Learn
  },
  {
    path: '/notifications',
    name: 'Alertas del clima',
    img: 'opt04',
    reel: true,
    moreInformation: false,
    component: Notifications
  },
  {
    path: '/tutorial',
    name: 'Conoce e instala Güip',
    img: 'opt02',
    reel: true,
    moreInformation: false,
    component: MainTutorial
  },
  {
    path: '/select-guip',
    name: 'Soluciones y precios',
    img: 'opt03',
    reel: true,
    moreInformation: false,
    component: SelectGuip
  },

  {
    path: '/share/:id',
    name: 'Héroes del Agua',
    img: 'opt05',
    reel: true,
    moreInformation: false,
    component: Share
  },
  {
    path: '/about',
    name: 'Sobre el proyecto',
    reel: false,
    moreInformation: true,
    component: About
  },
/*   {
    path: '/sponsors',
    name: 'Aliados',
    reel: false,
    moreInformation: true,
    component: Sponsors
  }, */
  {
    path: '/contact',
    name: 'Contacto',
    reel: false,
    moreInformation: true,
    component: Contact
  },
  {
    path: '/homeDetail/:idArticle',
    name: 'Notificación',
    component: HomeDetail
  },
  {
    path: '/notificationDetail/:idHis/:idHisDet',
    name: 'Notificación',
    component: NotificationDetail
  },
  {
    path: '/notification-validate/:idHis/:idHisDet/:idMunicipality',
    name: 'Validación',
    component: NotificationValidate
  },
  {
    path: '/notification-validate-sent/:idMunicipality',
    name: 'Validación enviada',
    component: NotificationValidateSent
  },
  {
    path: '/individual1',
    name: 'Individual',
    component: Individual1
  },
  {
    path: '/individual2/:idType',
    name: 'Individual',
    component: Individual2
  },
  {
    path: '/individual3/:idProfile',
    name: 'Individual',
    component: Individual3
  },
  {
    path: '/productive1',
    name: 'Productive',
    component: Productive1
  },
  {
    path: '/productive2/:idType',
    name: 'Productive',
    component: Productive2
  },
  {
    path: '/productive3/:idProfile',
    name: 'Productive',
    component: Productive3
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: MainTutorial
  },
  {
    path: '/tutorial-community1',
    name: 'Comunidad',
    component: TutorialCommunity
  },
  {
    path: '/tutorial-individual1',
    name: 'Individual',
    component: TutorialIndividual
  },
  {
    path: '/tutorial-productive1',
    name: 'Productivo',
    component: TutorialProductive
  },
  {
    path: '/landing1',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/package/:idType',
    name: 'Unboxing',
    component: Package
  },
  {
    path: '/package-main/:idType',
    name: 'Unboxing',
    component: Main
  },
   {
    path: '/package-unboxing/:idType',
    name: 'Unboxing',
    component: Unboxing
  },
  {
    path: '/package-assemble/:idType',
    name: 'Assemble',
    component: Assemble
  },
  //
  {
    path: '/selectVersion',
    name: 'Select version',
    component: SelectVersion
  },
  {
    path: '/v2/learn',
    name: 'Silabario del agua',
    component: SilabarioV2
  },
  {
    path: '/v2/silabarioV2Detail/:idArticle',
    name: 'Silabario del agua - Artículo',
    component: SilabarioV2Detail
  },
  {
    path: '/v2/alerts',
    name: 'Alertas',
    component: AlertsV2
  },
  {
    path: '/v2/price',
    name: 'Precio',
    component: PriceV2
  },
   {
    path: '/v2/maintenance',
    name: 'Mantenimiento',
    component: MaintenanceV2
  },
  {
    path: '/v2/heroes',
    name: 'Héroes del agua',
    component: HeroesV2
  },
  {
    path: '/v2/heroes-logged-in',
    name: 'Héroes del agua',
    component: HeroesV2LoggedIn
  },
  {
    path: '/share-logged-in',
    name: 'Héroes del agua',
    component: ShareLoggedIn
  },
  { path: '/', pathTo: '/selectVersion', name: 'Home', redirect: true }
];
export default ThemeRoutes;

import React from 'react';
import ReactPlayer from "react-player"


const MainTutorial = () => {

    return (
        <div>
            <div className="page-header">
                <img src="img/headers/header-videos_instalacion.png" alt="" />
            </div>
            <div className="page-title">
                Conoce e instala tu Güip
            </div>
            <div className="page-text">
                Sabemos que muchos lugares carecen de acceso al agua potable o disponen de un servicio irregular. Güip se ha desarrollado como una alternativa sostenible para disminuir esta problemática durante la época de lluvia.
                <br /><br />
                A continuación te presentamos los tres tipos de soluciones disponibles. Toca las imágenes para más información sobre cada una de ellas y conocer su forma de instalación.
            </div>

            <br />

            <div className="news-n">
                <a href={"/#/tutorial-community1"}>
                    <div className="article-box">
                        <div className="article-box-img">
                            <img src="img/tutorial/individual/rural.png" alt="" />
                        </div>
                        <div className="article-box-content">
                            <div className="article-box-text">Colecta y cosecha agua lluvia para una</div>
                            <div className="article-box-text"><h2>Solución domiciliar Rural</h2></div>
                            <div className="article-box-detail tutorial">Te invitamos a conocer más de esta solución dando clic en esta sección para brindarte detalles tú sistema de cosecha de aguas lluvias.</div>
                        </div>
                    </div>
                </a>

                <a href={"/#/tutorial-individual1"}>
                    <div className="article-box">
                        <div className="article-box-img">
                            <img src="img/tutorial/individual/urbana.png" alt="" />
                        </div>
                        <div className="article-box-content">
                            <div className="article-box-text">Colecta y cosecha agua lluvia para una</div>
                            <div className="article-box-text"><h2>Solución domiciliar Urbana</h2></div>
                            <div className="article-box-detail tutorial">Te invitamos a conocer más de esta solución dando clic en esta sección para brindarte detalles de tú sistema de cosecha de aguas lluvias.</div>
                        </div>
                    </div>
                </a>

                <a href={"/#/tutorial-productive1"}>
                    <div className="article-box">
                        <div className="article-box-img">
                            <img src="img/tutorial/productive/productivo.png" alt="" />
                        </div>
                        <div className="article-box-content">
                            <div className="article-box-text">Colecta y cosecha agua lluvia para una</div>
                            <div className="article-box-text"><h2>Solución Productiva</h2></div>
                            <div className="article-box-detail tutorial">Te invitamos a conocer más de esta solución dando clic en esta sección para brindarte detalles tú sistema de cosecha de aguas lluvias.</div>
                        </div>
                    </div>
                </a>

                <div>
                    <b>¿Cómo cuido mi sistema Güip?</b>
                    <br />
                    En esta sección te compartiremos información para que sepas cómo cuidar y darle mantenimiento a tu sistema Güip.
                </div>

                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=EExw5Ubzv8g" />
                    </div>
                </div>
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=KOmtgNDka3g" />
                    </div>
                </div>

                <div className="article-box">
                    <div className="article-box-content">
                        <center>
                            <div className="article-box-detail tutorial"><b>¡Descarga tu manual para instalar el sistema domiciliar rural!</b></div>
                            <a href="/manuales/manual_rural_guip2.pdf" target="_blank" rel="noopener noreferrer">
                                <button className="accept-notifications comparte">
                                    <img src="img/guip-style/heroes/descargar.svg" width="20%" alt="" />
                                    <br />
                                    Descargar manual
                                </button>
                            </a>
                        </center>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default MainTutorial;

import React from 'react';
import informationService from '../../services/informationService'
import { Modal, Button } from 'react-bootstrap'
import LoadingAnimation from '../../components/animations/loadingAnimation'

export default class Learn extends React.Component {

    state = {
        articles: [],
        articlesLoading: false,
        show: false,
        title: "",
        text: ""
    };

    a = () => {
        this.setState({ title: "Empatía" })
        this.setState({ text: "Entendemos el problema de acceso al agua y creamos soluciones apropiadas desde un enfoque centrado en las personas para mejorar la calidad de vida de los salvadoreños." })
        this.handleShow();
    }


    b = () => {
        this.setState({ title: "Colaboración" })
        this.setState({ text: "Somos una comunidad horizontal y colaborativa que reduciremos la brecha de acceso al agua mediante el aprovechamiento del agua lluvia y la educación en torno a sus beneficios." })
        this.handleShow();
    }

    c = () => {
        this.setState({ title: "Solidaridad" })
        this.setState({ text: "El agua es derecho de todas los salvadoreños y este espacio educativo e informativo permitirá que todas las personas o instituciones de manera solidaria puedan ser parte de la solución." })
        this.handleShow();
    }

    handleClose = () => {
        this.setState({ show: false })
    }
    handleShow = () => {
        this.setState({ show: true })
    }

    getArticles = () => {
        this.setState({
            articlesLoading: true
        })
        informationService.articles(2)
            .then(data => this.setState({
                articles: data.data,
                articlesLoading: false
            }))
            .catch(err => {
                console.log(err);
                //const cookies = new Cookies();
                //console.log(cookies.get('_ga'));
                this.setState({
                    articles: [],
                    articlesLoading: false
                })
                return null;
            });
    }

    componentDidMount() {
        this.getArticles();
    }

    render() {
        return (
            <div>
                <div className="page-header">
                    <img src="img/headers/header-escuela_lluvia.png" alt="" />
                </div>
                <div className="page-title">
                    Silabario del agua
                </div>
                <div className="page-text">
                    Nuestro compromiso para compartir contenido e información valiosa sobre el proceso de captar, cosechar y manejar el agua lluvia, se rige por nuestros 3 valores clave:
                </div>
                <br />
                <center>
                    <div className="learn-option" >
                        <div className="learn-option-name" id="a" onClick={() => this.a()}>
                            A<br /><div>Empatía</div>
                        </div>
                        <div className="learn-option-name" id="b" onClick={() => this.b()}>
                            B<br /><div>Colaboración</div>
                        </div>
                        <div className="learn-option-name" id="c" onClick={() => this.c()}>
                            C<br /><div>Solidaridad</div>
                        </div>
                    </div>
                </center>

                <br />

                <div className="header-section-home">
                    <hr />
                    <span>
                        Artículos para aprender
                    </span>
                </div>

                {(this.state.articles.length > 0)
                    ?
                    (this.state.articles.map((element, index) => {
                        return (
                            <a href={"/#/homeDetail/" + element.id} key={index}>
                                <div className="article-box" key={index}>
                                    <div className="article-box-img">
                                        <img src={((element.imgUrl === null) ? "img/articles/default.jpg" : element.imgUrl.substr(1))} alt="" />
                                    </div>
                                    <div className="article-box-content">

                                        <div className="article-box-claps">
                                            <img src="img/buttons/clap.svg" alt="" />
                                            <span>
                                                {element.claps} personas han aplaudido esto
                                            </span>
                                        </div>
                                        <div className="article-box-text">{element.title}</div>
                                        <div className="article-box-detail">Leer más</div>
                                    </div>
                                </div>
                            </a>
                        )
                    }))
                    : !(this.state.articles.articlesLoading) ? <center>No hay artículos disponibles.</center> : <LoadingAnimation height={100} width={100} />
                }

                <br />
                <center>
                    <div className="page-text title">
                        ¿Quieres tener más información? <br /><b>¡Contáctanos!</b>
                    </div>
                </center>


                <Modal
                    show={this.state.show}
                    onHide={() => this.handleClose()}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.text}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleClose()}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
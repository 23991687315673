import React from 'react';

const AssembleProductive = () => {

    return (
        <div>
            <div className="page-header" style={{ marginTop: "-30px" }}>
                <img src="img/headers/header-calculadora.png" alt="" />
                <a href="/#/select-guip">
                    <img src="img/buttons/arrow-back-info.svg" alt="" className="arrow-back-info" />
                </a>
            </div>

            <div className="page-title">
                Solución productiva
            </div>
            <div className="page-text">
                Ahora elige el tipo de actividad que realizas, seleccionando la imagen que más se parezca a tu negocio.
            </div>
            <br />
            <div className="news-n">

                <a href="/#/productive2/3">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/productive/avicola.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Avícola
                            </div>
                        </div>
                    </div>
                </a>

                <a href="/#/productive2/4">
                    <div className={"notification-validation-box"}>
                        <div className="notification-validation-box-img assemble">
                            <img className="validate" src="img/assemble/productive/agricola.png" alt="" />
                        </div>
                        <div className="notification-validation-box-content">
                            <div className="notification-validation-box-text validate-selection" style={{ textAlign: "left" }}>
                                Agrícola
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default AssembleProductive;

import React from 'react';
import SoundAnimation from '../../components/animations/soundAnimation'
import TutorialAnimation from '../../components/animations/tutorialAnimation'
import ReactPlayer from "react-player"

export default class SelectVersion extends React.Component {

    state = {
        voice: null,
        synth: null
    };

    componentDidMount() {
        let synth = window.speechSynthesis;
        this.setState({ synth: synth });
        let esp = 0
        const getVoices = () => {
            return new Promise(resolve => {
                let voices = synth.getVoices()
                if (voices.length) {
                    resolve(voices)
                    return
                }
                const voiceschanged = () => {
                    voices = synth.getVoices()
                    resolve(voices)
                }
                speechSynthesis.onvoiceschanged = voiceschanged
            })
        }

        const getVoice = async () => {
            (await getVoices()).forEach(voice => {
                console.debug(voice.name, voice.lang)
                if (voice.lang === "es-US") {
                    esp = voice
                    this.setState({ voice: esp });
                }
            })
        }
        getVoice()
    }

    speak = (message) => {
        let hi = new SpeechSynthesisUtterance(message);
        hi.pitch = 1;
        hi.rate = 1;
        hi.voice = this.state.voice;
        this.state.synth.speak(hi);
    }

    render() {
        return (
            <div>
                <br /><br /><br />
                <br /><br /><br />
                <center>
                    <div onClick={() => this.speak("Aquí aprenderás sobre la instalación y cuidados de tu Güip")}>
                        <SoundAnimation height={70} width={70} />
                    </div>
                </center>

                <br />

                <div className="page-title">
                    Cuidado e instalación
                </div>

                <div className="article-box">
                    <div className="article-box-img">
                        <TutorialAnimation />
                    </div>
                    <div className="article-box-content">
                        {/* <div className="article-box-text">Pasos</div> */}
                        <div className="article-box-text">Figura 1. Ilustración de la instalación del Sistema Domiciliar Rural.</div>
                        <div className="article-box-detail tutorial">Nota: Para más detalles y guía paso a paso, te invitamos a descargar el PDF del Manual de Instalación de esta solución.</div>
                    </div>
                    <center>
                        <div onClick={() => this.speak("Esta animación paso a paso te muestra cómo puedes instalar el sistema. Para más detalles y guía paso a paso te invitamos a descargar el PDF en la parte inferior de esta página.")}>
                            <SoundAnimation height={70} width={70} />
                        </div>
                    </center>
                    <br />
                </div>

                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=EExw5Ubzv8g" />
                    </div>
                </div>
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=-HThGly8oiM" />
                    </div>
                </div>
                <div className="article-box">
                    <div className="article-box-img">
                        <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=KOmtgNDka3g" />
                    </div>
                </div>

                <div className="article-box">
                    <div className="article-box-content">
                        <center>
                            <div className="article-box-detail tutorial"><b>¡Descarga tu manual para instalar el sistema domiciliar rural!</b></div>
                            <center>
                                <div onClick={() => this.speak("Si quieres descargar el manual de instalación de Güip, toca el botón que tiene la página.")}>
                                    <SoundAnimation height={70} width={70} />
                                </div>
                            </center>
                            <a href="/manuales/manual_rural_guip2.pdf" target="_blank" rel="noopener noreferrer">
                                <button className="accept-notifications comparte">
                                    <img src="img/guip-style/heroes/descargar.svg" width="20%" alt="" />
                                    <br />
                                    Descargar manual
                                </button>
                            </a>
                        </center>
                    </div>
                </div>
            </div>
        );
    }
}